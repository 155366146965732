export const checkInApi = [
    {
        day: 1,
        bonus: 1
    },
    {
        day: 2,
        bonus: 2
    },
    {
        day: 3,
        bonus: 2
    },
    {
        day: 4,
        bonus: 2
    },
    {
        day: 5,
        bonus: 3
    },
    {
        day: 6,
        bonus: 3
    },
    {
        day: 7,
        bonus: 3
    }
]