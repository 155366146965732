import React, { useState, useEffect } from 'react'
import { IoIosArrowRoundBack } from 'react-icons/io'
import './Profile.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toast'
import Loading from '../../Components/Loading'
import { useNavigate } from 'react-router-dom'
import img5 from '../../Images/Minesweeper/boomImg.png'

const OrderRecords = () => {
    const [orderType, setOrderType] = useState(1)
    const [myOrder, setMyOrder] = useState([])
    const [loader, setLoader] = useState(true)

    const navigate = useNavigate()

    async function getMyOrder() {
        let user = localStorage.getItem("user");

        if (orderType === 1) {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/myOrder`, { id: user })
                .then(response => {
                    setMyOrder(response.data.data)
                    setLoader(false)
                }).catch(error => {
                    toast.error(error.response.data?.error)
                    setLoader(false)
                })
        } else {
            if (orderType === 2) {
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/myOrder/dice`, { id: user })
                    .then(response => {
                        setMyOrder(response.data.data)
                        setLoader(false)
                    }).catch(error => {
                        toast.error(error.response.data?.error)
                        setLoader(false)
                    })
            } else {
                if (orderType === 3) {
                    axios.post(`${process.env.REACT_APP_BACKEND_URL}/myOrder/sweeper`, { id: user })
                        .then(response => {
                            setMyOrder(response.data.data)
                            setLoader(false)
                        }).catch(error => {
                            toast.error(error.response.data?.error)
                            setLoader(false)
                        })
                }
            }
        }
    }

    useEffect(() => {
        getMyOrder()
    }, [orderType])

    return (
        <div style={{ minHeight: '100vh', background: '#fff' }}>
            {loader ? <Loading /> : <></>}
            <div className='app__top__nav'>
                <div className="top__nav__cols">
                    <div className="top__nav__back__btn" onClick={() => navigate(-1)}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="top__nav__cols">
                    <center>
                        <div className="top__nav__title">Order</div>
                    </center>
                </div>
                <div className="top__nav__cols"></div>
            </div>
            <div className="parity__records__section__nav__headd"></div>
            <div className="parity__records__section__nav">
                <div className={orderType !== 1 ? "parity__records__section__nav__col" : "parity__records__section__nav__col active__parity__record__nav"} onClick={() => setOrderType(1)}>Fast Parity</div>
                <div className={orderType !== 2 ? "parity__records__section__nav__col" : "parity__records__section__nav__col active__parity__record__nav"} onClick={() => setOrderType(2)}>Dice</div>
                <div className={orderType !== 3 ? "parity__records__section__nav__col" : "parity__records__section__nav__col active__parity__record__nav"} onClick={() => setOrderType(3)}>Minesweeper</div>
            </div>
            {myOrder.length === 0 || !myOrder ? (
                <div className="orderRecordSillyText">No Record Found!</div>
            ) : (
                orderType === 2 ? (
                    myOrder.map((item, index) => {
                        return (
                            <div className="orderRecordsBoxContainer" key={index}>
                                <div className="orderRecordsBoxOuter">
                                    <div className="orderRecordBox">
                                        <div className="orderRecordBoxTop">
                                            <div className="orderRecordBoxTopLeft">Period {item.period}</div>
                                            <div className="orderRecordBoxTopRight">{item.time}</div>
                                        </div>
                                        <div className="orderRecordBoxBottom">
                                            <div className="orderRecordBoxBottomHead">
                                                <div className="orderRecordBoxBottomSelectCol">Select</div>
                                                <div className="orderRecordBoxBottomPointCol">Point</div>
                                                <div className="orderRecordBoxBottomResultCol">Result</div>
                                                <div className="orderRecordBoxBottomAmountCol">Amount</div>
                                            </div>
                                            <div className="orderRecordBoxBottomBody">
                                                <div className="orderRecordBoxBottomSelectCol">
                                                    <div style={{ height: 50, width: 50 }}>
                                                        <div className="parity__records__circle" style={{ background: '#312d47', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, fontSize: 14 }}> {parseFloat(item.select) === 100 ? "" : item.select} </div>
                                                    </div>
                                                </div>
                                                <div className="orderRecordBoxBottomPointCol">₹{item.amount}</div>
                                                <div className="orderRecordBoxBottomResultCol" style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div style={{ height: 50, width: 50 }}>
                                                        <div className="parity__records__circle" style={{ background: '#312d47', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, fontSize: 14 }}> {parseFloat(item.result) === 100 ? "" : item.result} </div>
                                                    </div>
                                                </div>
                                                <div style={parseFloat(item.select) <= parseFloat(item.result) ? { color: 'red' } : { color: 'green' }} className="orderRecordBoxBottomAmountCol pointLL">{parseFloat(item.select) <= parseFloat(item.result) ? `-₹${item.amount}` : `+₹${item.amount * (95 / parseFloat(item.select)).toFixed(2)}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    orderType === 1 ? (
                        myOrder.map((item, index) => {
                            let g = item.result === 1 || item.result === 3 || item.result === 5 || item.result === 7 || item.result === 9
                            let r = item.result === 0 || item.result === 2 || item.result === 4 || item.result === 6 || item.result === 8
                            let v = item.result === 0 || item.result === 5

                            return (
                                <div className="orderRecordsBoxContainer" key={index}>
                                    <div className="orderRecordsBoxOuter">
                                        <div className="orderRecordBox">
                                            <div className="orderRecordBoxTop">
                                                <div className="orderRecordBoxTopLeft">Period {item.period}</div>
                                                <div className="orderRecordBoxTopRight">{item.time}</div>
                                            </div>
                                            <div className="orderRecordBoxBottom">
                                                <div className="orderRecordBoxBottomHead">
                                                    <div className="orderRecordBoxBottomSelectCol">Select</div>
                                                    <div className="orderRecordBoxBottomPointCol">Point</div>
                                                    <div className="orderRecordBoxBottomResultCol">Result</div>
                                                    <div className="orderRecordBoxBottomAmountCol">Amount</div>
                                                </div>
                                                <div className="orderRecordBoxBottomBody">
                                                    <div className="orderRecordBoxBottomSelectCol">
                                                        <div style={{ height: 50, width: 50 }}>
                                                            <div className="parity__records__circle">
                                                                <div className="parity__records__circle__no">{item.select === 10 ? "?" : item.select}</div>
                                                                <div className="parity__records__circle__inner">
                                                                    <div className="parity__records__circle__col" style={item.type === 'number' ? item.select === 1 || item.select === 3 || item.select === 7 || item.select === 9 || item.select === 5 ? { background: "rgb(0, 194, 130)" } : item.select === 10 ? { background: "#888" } : { background: "rgb(250, 60, 9)" } : item.select === 'R' ? { background: "rgb(250, 60, 9)" } : item.select === 'G' ? { background: "rgb(0, 194, 130)" } : { background: "rgb(102, 85, 211)" }}></div>
                                                                    <div className="parity__records__circle__col" style={item.type === 'number' ? item.select === 1 || item.select === 3 || item.select === 7 || item.select === 9 ? { background: "rgb(0, 194, 130)" } : item.select === 0 || item.select === 5 ? { background: "rgb(102, 85, 211)" } : item.select === 10 ? { background: "#888" } : { background: "rgb(250, 60, 9)" } : item.select === 'R' ? { background: "rgb(250, 60, 9)" } : item.select === 'G' ? { background: "rgb(0, 194, 130)" } : { background: "rgb(102, 85, 211)" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="orderRecordBoxBottomPointCol">₹{item.amount}</div>
                                                    <div className="orderRecordBoxBottomResultCol" style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ height: 50, width: 50 }}>
                                                            <div className="parity__records__circle">
                                                                <div className="parity__records__circle__no">{!item.result || item.result === 10 ? "" : item.result}</div>
                                                                <div className="parity__records__circle__inner">
                                                                    <div className="parity__records__circle__col" style={item.result === 1 || item.result === 3 || item.result === 7 || item.result === 9 || item.result === 5 ? { background: "rgb(0, 194, 130)" } : !item.result ? { background: "#888" } : { background: "rgb(250, 60, 9)" }}></div>
                                                                    <div className="parity__records__circle__col" style={item.result === 1 || item.result === 3 || item.result === 7 || item.result === 9 ? { background: "rgb(0, 194, 130)" } : item.result === 2 || item.result === 4 || item.result === 6 || item.result === 8 ? { background: "rgb(250, 60, 9)" } : item.result === 0 || item.result === 5 ? { background: "rgb(102, 85, 211)" } : { background: "#888" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="orderRecordBoxBottomAmountCol pointLL" style={item.selectType === 'number' ? parseFloat(item.select) === parseFloat(item.result) ? { color: 'green' } : { color: 'red' } : item.select === 'G' && g || item.select === 'R' && r ? { color: 'green' } : item.select === 'V' && v ? { color: 'green' } : { color: 'red' }}>{item.selectType === 'number' ? parseFloat(item.select) === parseFloat(item.result) ? `+₹${item.amount * 9}` : `-₹${item.amount}` : item.select === 'G' && g || item.select === 'R' && r ? `+₹${item.amount * 2}` : item.select === 'V' && v ? `+₹${item.amount * 4.5}` : `-₹${item.amount}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        myOrder.map((item, index) => {
                            return (
                                <div className="orderRecordsBoxContainer" style={{ padding: '0 3%'}} key={index}>
                                    <div className="sweeperOwnOrderBox" key={index}>
                                        <div className="sweeperOrderBoxBoard" style={item.size === 2 ? { gap: 4, gridTemplateColumns: 'repeat(2, 1fr)', padding: 2 } : { gap: 2, gridTemplateColumns: 'repeat(4, 1fr)', padding: 1 }}>
                                            {item.board?.map((item2, index2) => {
                                                return (
                                                    item.win ? item.bomb !== item2 ? (
                                                        item.checked?.includes(item2) ? (
                                                            <div className="minesweeperCheckedBox"></div>
                                                        ) : (
                                                            <div style={{ background: '#f0f0f0' }}></div>
                                                        )
                                                    ) : (
                                                        <div className='frozenBombCol'></div>
                                                    ) : item.bomb === item2 ? (
                                                        <div className='minesweeperBombBox'>
                                                            <img src={img5} width="75%" alt="" />
                                                        </div>
                                                    ) : item.checked?.includes(item2) ? (
                                                        <div className='minesweeperCheckedBox'></div>
                                                    ) : (
                                                        <div style={{ background: '#f5f5f5' }}></div>
                                                    )
                                                )
                                            })}
                                        </div>
                                        <div className="sweeperOrderBoxRight">
                                            <div className="sweeperOrderBoxRightTop">
                                                <div className="sweeperOrderBoxRightTopCol">
                                                    <div className="sweeperOrderBoxColTop">Point</div>
                                                    <div className="sweeperOrderBoxColBottom">₹{item.amount}</div>
                                                </div>
                                                <div className="sweeperOrderBoxRightTopCol">
                                                    <div className="sweeperOrderBoxColTop">Pass</div>
                                                    <div className="sweeperOrderBoxColBottom">{item.checked?.length}</div>
                                                </div>
                                                <div className="sweeperOrderBoxRightTopCol">
                                                    <div className="sweeperOrderBoxColTop">Bonus</div>
                                                    <div className="sweeperOrderBoxColBottom">+₹{item.win ? item.ATN?.toFixed(2) : 0}</div>
                                                </div>
                                            </div> <br />
                                            <div className="sweeperOrderBoxRightBottom">
                                                <div className="sweeperOrderBoxColTop">Time: {item.date}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    )
                )
            )}

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default OrderRecords