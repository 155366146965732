import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { IoIosArrowRoundBack } from 'react-icons/io'
import './Invite.css'
import Loading from '../../Components/Loading'
import { ToastContainer, toast } from 'react-toast'

const Commission = () => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true);

    async function fetchData() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/getDailyRecord`, { id: user })
            .then(response => {
                setData(response.data.data)
                setLoader(false)
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div style={{ minHeight: '100vh', background: '#fff' }}>
            {loader ? <Loading /> : <></>}
            <div className='app__top__nav' style={{ background: '#fff', borderBottom: '15px solid #f0f0f0', height: 60 }}>
                <div className="top__nav__cols" style={{ flexBasis: 50, width: '100%' }}>
                    <div className="top__nav__back__btn" onClick={() => window.history.go(-1)}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="top__nav__cols" style={{ flexBasis: "calc(100% - 100px)", width: '100%' }}>
                    <center>
                        <div className="top__nav__title" style={{ fontSize: 16, fontWeight: 500 }}>Daily Record</div>
                    </center>
                </div>
                <div className="top__nav__cols" style={{ flexBasis: 50, width: '100%' }}></div>
            </div>

            <div className="dailyRecordContainer">
                <div className="dailyRecord" style={{ fontSize: 15, color: '#aaa' }}>
                    <div className="dailyRecordLeft">Head</div>
                    <div className="dailyRecordRight">Income</div>
                </div>

                {data.length === 0 ? (
                    <div className="orderRecordSillyText">No Record Found!</div>
                ) : (
                    data.map((item, index) => {
                        let p = item._id;
                        let a = p.toString()

                        return (
                            <div className="dailyRecord" key={index}>
                                <div className="dailyRecordLeft">{a.slice(0, 2) + '/' + a.slice(2, 4) + '/' + a.slice(4)}</div>
                                <div className="dailyRecordRight">₹{item.amount}</div>
                            </div>
                        )
                    })
                )}
            </div>


            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Commission