import React from 'react'
import './Components.css'
import { IoIosArrowRoundBack } from 'react-icons/io'

const Topbar = () => {
  return (
    <div className='app__top__nav'>
        <div className="top__nav__cols">
            <div className="top__nav__back__btn">
                <IoIosArrowRoundBack /> 
            </div>
        </div>
        <div className="top__nav__cols">Recharge</div>
        <div className="top__nav__cols">
            <div className="recharge__records__button">Records</div>
        </div>
    </div>
  )
}

export default Topbar