import React, { useState, useEffect } from 'react'
import './Auth.css'
import img from '../../Images/login.png'
import { IoKeyOutline } from 'react-icons/io5'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { ImMobile } from 'react-icons/im'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toast'

const Register = () => {
    const query = new URLSearchParams(window.location.search);
    const inviter = query.get('ref')

    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState(false)
    const [counter, setCounter] = useState(0)
    const [view, setView] = useState(false)
    const [user, setUser] = useState({
        phoneNumber: "",
        otp: "",
        password: "",
        inviter: inviter ? inviter : null
    });

    async function sendOtp() {
        setLoading(true);
        setCounter(120)

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/send-otp`, { phoneNumber: user.phoneNumber })
            .then(response => {
                setLoading(false)
                setOtp(true)
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    async function register() {
        setLoading(true);

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, { phoneNumber: user.phoneNumber, password: user.password, otp: user.otp, inviter: user.inviter })
            .then(response => {
                localStorage.setItem("user", response.data.user);
                window.location.replace('/')
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1)
            }
        }, 1000);

        return () => clearInterval(interval);
    });

    return (
        <div>
            <div className='auth__page'><br /><br />
                <center>
                    <div className="auth__screen">
                        <div className="auth__screen__logo">
                            <img src={img} height={45} alt="" />
                        </div>
                    </div>
                    <br /><br /><br />

                    <div className="auth__form">
                        <div className="auth__form__input">
                            <div className="auth__form__input__left" style={{ fontSize: 23 }}><ImMobile /></div>+91
                            <input type="text" value={user.phoneNumber} onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })} placeholder='Enter Mobile Number' />
                        </div>
                        <div className="auth__form__otp__field input__margin">
                            <input type="text" value={user.otp} onChange={(e) => setUser({ ...user, otp: e.target.value })} placeholder='Enter OTP' />
                            {user.phoneNumber.length === 10 && counter === 0 ? <button className="send__otp__btn" onClick={sendOtp}>OTP</button> : <button className="send__otp__btn" style={{ background: "#ccc", color: "#555" }}> {counter > 0 ? `${counter}s` : "OTP"} </button>}
                        </div>
                        <div className="auth__form__input input__margin">
                            <div className="auth__form__input__left">
                                <IoKeyOutline style={{ fontSize: 22 }} />
                            </div>
                            <input type={view ? "text" : "password"} value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} placeholder='Enter Password (min: 6 characters)' />
                            <div className="auth__form__input__left" style={{ background: 'transparent', color: '#777' }}>
                                {view ? <BsFillEyeSlashFill style={{ fontSize: 22 }} onClick={() => setView(false)} /> : <BsFillEyeFill style={{ fontSize: 22 }} onClick={() => setView(true)} />}
                            </div>
                        </div>
                        {user.phoneNumber.length === 10 && user.password.length > 5 ? <button className="login__btn" style={{ background: "rgb(0, 147, 255)", color: "#fff" }} onClick={register}>Register</button> : <button className="login__btn">Register</button>}
                        <div className='login__span'>Registered Account? &nbsp; <a href="/login">Login</a></div>
                    </div>
                </center>
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Register