import React, { useState, useEffect } from 'react'
import { IoIosArrowRoundBack, IoIosArrowForward } from 'react-icons/io'
import './Profile.css'
import Loading from '../../Components/Loading'
import { FiArrowUpRight, FiArrowDownLeft, FiLogOut } from 'react-icons/fi'
import { MdOnlinePrediction } from 'react-icons/md'
import { TbUsers } from 'react-icons/tb'
import { BiSupport } from 'react-icons/bi'
import { FaTelegramPlane } from 'react-icons/fa'
import axios from 'axios'
import BottomBar from '../../Components/BottomBar'
import { ToastContainer, toast } from 'react-toast'
import { useNavigate } from 'react-router-dom'
import picImg from '../../Images/defaultAvatar.png'

const Profile = () => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState({
        name: '',
        id: '',
        phone: ''
    })

    const navigate = useNavigate()

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/id`, { id: user })
            .then(response => {
                setData({
                    ...data,
                    id: response.data.id,
                    name: response.data.name,
                    phone: response.data.phone
                })

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBal()
    }, [])

    function logoutUser() {
        localStorage.removeItem("user");
        navigate('/')
    }

    return (
        <div>
            {loader ? <Loading /> : <></>}
            <BottomBar page={4} />
            <div className='app__top__nav'>
                <div className="top__nav__cols">
                    <div className="top__nav__back__btn" onClick={() => navigate('/home')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="top__nav__cols">
                    <center>
                        <div className="top__nav__title">Profile</div>
                    </center>
                </div>
                <div className="top__nav__cols"></div>
            </div>

            <div className="user__details__section">
                <div className="user__pic">
                    <img src={picImg} height="100%" alt="" />
                </div>
                <div className="user__details__section__right">
                    <div className="user__details__section__right__col"><b>ID: {data.id}</b></div>
                    <div className="user__details__section__right__col">Phone: {data.phone}</div>
                    <div className="user__details__section__right__col">Name: {data.name}</div>
                </div>
            </div>

            <div className="profile__records__section">
                <div className="profile__records__section__col" onClick={() => navigate('/orderRecords')}>
                    <div className="profile__records__section__col__left">
                        <div className="profile__record__section__col__icon">
                            <MdOnlinePrediction />
                        </div>
                        <div className="profile__record__section__col__name">Order Records</div>
                    </div>
                    <div className="profile__records__section__col__right">
                        <IoIosArrowForward />
                    </div>
                </div>
                <div className="profile__records__section__col" onClick={() => navigate('/financialRecords')}>
                    <div className="profile__records__section__col__left">
                        <div className="profile__record__section__col__icon">
                            <TbUsers />
                        </div>
                        <div className="profile__record__section__col__name">Financial Details</div>
                    </div>
                    <div className="profile__records__section__col__right">
                        <IoIosArrowForward />
                    </div>
                </div>
            </div>

            <div className="profile__records__section">
                <div className="profile__records__section__col">
                    <div className="profile__records__section__col__left">
                        <div className="profile__record__section__col__icon">
                            <BiSupport />
                        </div>
                        <div className="profile__record__section__col__name">Support</div>
                    </div>
                    <div className="profile__records__section__col__right">
                        <IoIosArrowForward />
                    </div>
                </div>
                <div className="profile__records__section__col">
                    <div className="profile__records__section__col__left">
                        <div className="profile__record__section__col__icon">
                            <FaTelegramPlane />
                        </div>
                        <div className="profile__record__section__col__name">Follow Us</div>
                    </div>
                    <div className="profile__records__section__col__right">
                        <IoIosArrowForward />
                    </div>
                </div>
            </div>

            <div className="profile__records__section">
                <div className="profile__records__section__col" onClick={logoutUser}>
                    <div className="profile__records__section__col__left">
                        <div className="profile__record__section__col__icon">
                            <FiLogOut />
                        </div>
                        <div className="profile__record__section__col__name">Sign Out</div>
                    </div>
                    <div className="profile__records__section__col__right">
                        <IoIosArrowForward />
                    </div>
                </div>
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Profile