import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Home.css'
import { BiSupport } from 'react-icons/bi'
import { FiLogOut, FiArrowUp, FiArrowDown } from 'react-icons/fi'
import { FaTelegramPlane } from 'react-icons/fa'
import img1 from '../../Images/coming-soon.jpg'
import img2 from '../../Images/fast-parity.jpg'
import img3 from '../../Images/indexTaskV1.png'
import img4 from '../../Images/indexCheckV1.png'
import img5 from '../../Images/diceEnterV2.png'
import img6 from '../../Images/Minesweeper/main.png'
import img7 from '../../Images/Circle/main.png'
import Loading from '../../Components/Loading'
import axios from 'axios'
import BottomBar from '../../Components/BottomBar'
import { ToastContainer, toast } from 'react-toast'

const Home = () => {
    const [loader, setLoader] = useState(true)
    const [balance, setBalance] = useState("0")

    const navigate = useNavigate()

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/balance`, { id: user })
            .then(response => {
                setBalance(parseFloat(response.data?.withdraw) + parseFloat(response.data?.deposit))
                setLoader(false)
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBal()
    }, [])

    return (
        <div className='home__page'>
            {loader ? <Loading /> : <></>}

            <BottomBar page={1} />
            <div className="home__page__nav">
                <div className="home__page__nav__left">Home</div>
                <div className="home__page__nav__right">
                    <div className="home__page__nav__right__cols__container">
                        <div className="home__page__nav__right__col">
                            <div className="homepage__right__nav__col__icon">
                                <BiSupport />
                            </div>
                            <div className="homepage__right__nav__col__icon">
                                <FaTelegramPlane />
                            </div>
                            <div className="homepage__right__nav__col__icon">
                                <FiLogOut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="balance__section__container">
                <div className="balance__section">
                    <div className="balance__section__left">
                        <div className="balance__section__text">My Balance</div>
                        <div className="balance__value"><span style={{ fontSize: 18 }}>₹</span>{balance}</div>
                    </div>
                    <div className="balance__section__right">
                        <div className="balance__section__right__inner">
                            <button className="balance__section__recharge__btn" onClick={() => navigate('/recharge')}>Recharge</button> <br />
                            <button className="balance__section__withdrawal__btn" style={{ marginTop: 5 }} onClick={() => navigate('/withdraw')}>Withdrawal</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="balance__section__bottom">
                <div className="balance__section__bottom__cols" onClick={() => navigate('/task')}>
                    <div className="balance__section__bottom__icon">
                        <img src={img3} height={'100%'} alt="" />
                    </div>
                    <div className="balance__section__bottom__cols__name">Task Reward</div>
                </div>
                <div className="balance__section__bottom__cols" onClick={() => navigate('/check-in')}>
                    <div className="balance__section__bottom__icon">
                        <img src={img4} height={'100%'} alt="" />
                    </div>
                    <div className="balance__section__bottom__cols__name">Check In</div>
                </div>
            </div>

            <div className="homepage__game__screen">
                <div className="homepage__game__cols" onClick={() => navigate('/game/fastParity')}>
                    <div className="homepage__game__cols__icon">
                        <img src={img2} alt="" style={{ borderRadius: 10 }} />
                    </div>
                </div>
                <div className="homepage__game__cols" onClick={() => navigate('/game/dice')}>
                    <div className="homepage__game__cols__icon">
                        <img src={img5} alt="" style={{ borderRadius: 10 }} />
                    </div>
                </div>
                <div className="homepage__game__cols" onClick={() => navigate('/game/minesweeper')}>
                    <div className="homepage__game__cols__icon">
                        <img src={img6} alt="" style={{ borderRadius: 10 }} />
                    </div>
                </div>
                <div className="homepage__game__cols">
                    <div className="homepage__game__cols__icon">
                        <img src={img1} alt="" style={{ borderRadius: 10 }} />
                    </div>
                </div>
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Home