import React, { useState, useEffect } from 'react'
import { IoIosArrowRoundBack } from 'react-icons/io'
import './Profile.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toast'
import Loading from '../../Components/Loading'
import { useNavigate } from 'react-router-dom'

const FinancialRecords = () => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true)

    const navigate = useNavigate()

    async function fetchData() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetchFinancialRecords`, { id: user })
            .then(response => {
                setData(response.data.data)
                setLoader(false)
            }).catch(error => {
                toast.error(error.response.data?.error)
                setLoader(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            {loader ? <Loading /> : <></>}
            <div className='app__top__nav'>
                <div className="top__nav__cols">
                    <div className="top__nav__back__btn" onClick={() => navigate(-1)}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="top__nav__cols">
                    <center>
                        <div className="top__nav__title">Diary Record</div>
                    </center>
                </div>
                <div className="top__nav__cols"></div>
            </div>
            {data.length === 0 ? (
                <div className="financialSillyText">No Records Found!</div>
            ) : (
                data.map((item, index) => {
                    return (
                        <div className="financialRecordBox" key={index}>
                            <div className="financialRecordLeft">
                                <div className="financialRecordBoxImg">
                                    <img src={item.image} height="50px" alt="" />
                                </div>
                                <div className="financialRecordLeftRight">
                                    <div className="financialRecordLeftTop">{item.title}</div>
                                    <div className="financialRecordLeftBottom">{item.date}</div>
                                </div>
                            </div>
                            <div className="financialRecordRight">{item.type ? `+₹${item.amount}` : `-₹${item.amount}`}</div>
                        </div>
                    )
                })
            )}
        </div>
    )
}

export default FinancialRecords