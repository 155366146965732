import React, { useState, useEffect } from 'react'
import './Recharge.css'
import Topbar from '../../Components/Topbar'
import { IoIosArrowRoundBack } from 'react-icons/io'
import axios from 'axios'
import Loading from '../../Components/Loading'
import RechargeScreen from './RechargeScreen'
import BottomBar from '../../Components/BottomBar'
import { ToastContainer, toast } from 'react-toast'

const Recharge = () => {
    const [loader, setLoader] = useState(true)
    const [balance, setBalance] = useState("0")
    const [amount, setAmount] = useState("")
    const [deposit, setDeposit] = useState(false)

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/balance`, { id: user })
            .then(response => {
                setBalance(parseFloat(response.data?.withdraw) + parseFloat(response.data?.deposit))
                setLoader(false)
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }

    async function reqDeposit() {
        if (amount < 100) return toast.error('Minimum Deposit is ₹100');

        let user = localStorage.getItem("user");
        setLoader(true)

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/r`, { id: user, amount }).then(response => {
            window.location.replace(response.data.url)
        }).catch(error => {
            setLoader(false)
            toast.error(error.response.data?.error)
        })
    }

    useEffect(() => {
        fetchBal()
    }, [])
    return (
        <div style={{ minHeight: '100vh', background: '#fff' }}>
            {loader ? <Loading /> : <></>}

            <BottomBar page={3} />
            {deposit ? <RechargeScreen amount={amount} /> : (
                <div>
                    <div className='app__top__nav'>
                        <div className="top__nav__cols">
                            <div className="top__nav__back__btn" onClick={() => window.location.replace('/home')}>
                                <IoIosArrowRoundBack />
                            </div>
                        </div>
                        <div className="top__nav__cols">
                            <center>
                                <div className="top__nav__title">Recharge</div>
                            </center>
                        </div>
                        <div className="top__nav__cols">
                            <div className="recharge__records__button" onClick={() => window.location.replace('/rechargeRecords')}>Records</div>
                        </div>
                    </div>

                    <div className="recharge__page__balance__section">
                        <div className="recharge__page__balance__section__inner">
                            <center>
                                <div className="recharge__page__balance__section__top">Balance</div>
                                <div className="recharge__page__balance__section__bottom">₹{balance}</div>
                            </center>
                        </div>
                    </div>

                    <div className="recharge__screen">
                        <div className="amount__field">Amount</div>
                        <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='₹ (400 - 150000)' />

                        <div className="recharge__price__options">
                            <div className="recharge__price__option" onClick={() => setAmount(468)}>₹468</div>
                            <div className="recharge__price__option" onClick={() => setAmount(1515)}>₹1515</div>
                            <div className="recharge__price__option" onClick={() => setAmount(4545)}>₹4545</div>
                            <div className="recharge__price__option" onClick={() => setAmount(5000)}>₹5000</div>
                            <div className="recharge__price__option" onClick={() => setAmount(8000)}>₹8000</div>
                            <div className="recharge__price__option" onClick={() => setAmount(10000)}>₹10000</div>
                        </div>

                        <div className="recharge__button">
                            {amount > 99 ? <button className="recharge__btn" onClick={reqDeposit}>Recharge</button> : <button className="recharge__btn" style={{ background: '#ccc', color: '#555'}}>Recharge</button>}
                        </div>
                    </div>
                </div>
            )}

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Recharge