import React, { useState, useEffect } from 'react';
import './Minesweeper.css';
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import { IoMdClose } from 'react-icons/io'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toast'
import { io } from 'socket.io-client';
import sweeperWin from '../../../Images/Minesweeper/boomWinImgNew.png'
import kuddal from '../../../Images/Minesweeper/digger.png'
import sweeperLose from '../../../Images/Minesweeper/boomLoseImgNew.png'
import img1 from '../../../Images/Minesweeper/Coin_b.png'
import img2 from '../../../Images/Minesweeper/boomWallet.png'
import img5 from '../../../Images/Minesweeper/boomImg.png'
import img6 from '../../../Images/Minesweeper/frozenBoomNew1.png'

const socket = io.connect(process.env.REACT_APP_BACKEND_URL, { transports: ["websocket", "polling", "flashsocket"], forceNew: true });

const Minesweeper = () => {
    const [size, setSize] = useState(2)
    const [start, setStart] = useState(false)
    const [checkedBox, setCheckedBox] = useState([])
    const [betAmount, setBetAmount] = useState(10)
    const [balance, setBalance] = useState(0)
    const [loader, setLoader] = useState(false)
    const [gameLoader, setGameLoader] = useState([])
    const [orderType, setOrderType] = useState(false)
    const [order, setOrder] = useState([])
    const [myOrder, setMyOrder] = useState([])
    const [result, setResult] = useState([])
    const [bomb, setBomb] = useState('')
    const [resultPopup, setResultPopup] = useState({
        popup: false,
        win: true,
        amount: 18
    })
    const [game, setGame] = useState({
        id: '',
        amount: '',
        ATN: '',
        NCA: ''
    })
    const [popup, setPopup] = useState({
        popup: false,
        type: true,
        select: ''
    })

    let board = [];

    for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
            let id = parseFloat(size + "" + i + "" + j)
            board.push(<div key={id} className={checkedBox.includes(id) ? 'sweeperCheckedBoardCol' : bomb === id ? 'minesweeperBombBox' : 'sweeperBoardCol'} style={size === 2 ? { borderRadius: 5 } : size === 4 ? { borderRadius: 3 } : { borderRadius: 2 }} onClick={() => openBox(id)}>
                {gameLoader[id] ? (
                    <div className="minesweeperLoaderCol">
                        <img src={kuddal} width="65%" alt="" />
                    </div>
                ) : (
                    bomb === id ? (
                        <>
                            <img src={img5} width="75%" alt="" />
                        </>
                    ) : (
                        <></>
                    )
                )}
            </div>)
        }
    }

    async function openBox(prop) {
        if (checkedBox.includes(prop)) return;
        let user = localStorage.getItem("user");

        if (gameLoader[prop] || checkedBox.includes(prop)) return;

        setGameLoader({
            ...gameLoader,
            [prop]: true
        })

        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/claimBox`, { user: user, id: game.id, box: prop })
            .then(response => {
                setGameLoader({
                    ...gameLoader,
                    [prop]: false
                })

                if(response.data.win) {
                    setResultPopup({
                        ...resultPopup,
                        win: true,
                        popup: true,
                        amount: response.data.amount
                    })

                    setResult({
                        board: response.data.board,
                        bomb: response.data.bomb,
                        checked: response.data.checked
                    })
                }

                if (!response.data.bomb) {
                    setGame({
                        ...game,
                        amount: response.data.amount,
                        ATN: response.data.ATN,
                        NCA: response.data.NCA
                    })

                    setCheckedBox(response.data.checked)

                    socket.emit('betSweeper', { user: response.data.user, period: response.data.date, size: response.data.size, select: response.data.select, amount: response.data.add })
                } else {
                    setBomb(response.data.box)
                    setGameLoader([])

                    const timerId = setTimeout(() => {
                        setResultPopup({
                            ...resultPopup,
                            popup: true,
                            win: false,
                            amount: response.data.amount
                        })

                        setBomb(response.data.box)

                        setResult({
                            board: response.data.board,
                            checked: response.data.checked,
                            bomb: response.data.box
                        })
                    }, 2000);

                    return () => clearTimeout(timerId);
                }
            }).catch(error => { })
    }

    async function changeGameSize(prop) {
        if (start) return;
        setSize(prop)
    }

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/balance`, { id: user })
            .then(response => {
                setBalance(parseFloat(response.data?.withdraw) + parseFloat(response.data?.deposit))
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBal()
    }, [popup.popup])

    useEffect(() => {
        for (let i = 0; i < checkedBox.length; i++) {
            let c = checkedBox[i];
            if (gameLoader[c]) {
                setGameLoader({
                    ...gameLoader,
                    [c]: false
                })
            }
        }
    })

    async function fetchBet() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/pendingSweeperGame`, { id: user })
            .then(response => {
                if (response.data.playing) {
                    setStart(true)
                    setSize(response.data.size)
                    setGame({
                        ...game,
                        id: response.data.id,
                        amount: response.data.amount,
                        ATN: response.data.ATN,
                        NCA: response.data.NCA
                    })

                    setCheckedBox(response.data.checked)
                }
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBet()
    }, [])

    async function confirmBet() {
        let user = localStorage.getItem("user");
        setPopup({ ...popup, popup: false })
        setLoader(true)

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/placeSweeperBet`, { amount: betAmount, size, user: user }).then((response) => {
            setLoader(false)
            toast.success('Bet placed successfully')
            setStart(true)
            setGame({
                ...game,
                id: response.data.id,
                amount: response.data.amount,
                ATN: response.data.ATN,
                NCA: response.data.NCA
            })

            setCheckedBox([])
        }).catch(error => {
            setLoader(false)
            toast.error(error.response.data?.error)
        })
    }

    async function getMyOrder() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/myOrder/sweeper`, { id: user })
            .then(response => {
                setMyOrder(response.data.data)
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        getMyOrder()
    }, [orderType])

    useEffect(() => {
        if (!socket) return;

        socket.on('betForwardSweeper', ({ period, user, select, amount, size }) => {
            console.log(select)
            setOrder((state) => [
                ...state,
                {
                    period: period,
                    user: user,
                    select: select,
                    amount: amount,
                    size: size
                },
            ]);
        });

        return () => socket.off('betForwardSweeper');
    }, [socket]);

    function closePopup() {
        setResultPopup({
            ...resultPopup,
            popup: false
        })

        setStart(false)
        setCheckedBox([])
        setBomb('')
    }

    async function stopGame() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/stopGame`, { user: user, id: game.id })
            .then(response => {
                setGameLoader([])
                setResultPopup({
                    ...resultPopup,
                    win: true,
                    popup: true,
                    amount: response.data.amount
                })

                setResult({
                    board: response.data.board,
                    bomb: response.data.bomb,
                    checked: response.data.checked
                })
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    return (
        <div style={{ minHeight: '100vh', background: '#fff' }}>
            {resultPopup.popup ? (
                <div className="minesweeperResultPopupContainer">
                    <div className="minesweeperResultPopup">
                        <div className="minesweeperDecisionImg">
                            <img src={resultPopup.win ? sweeperWin : sweeperLose} style={resultPopup.win ? { marginTop: -177, marginLeft: '-5%' } : { marginTop: -95, marginLeft: '-5%' }} width="110%" alt="" />
                        </div>
                        <center>
                            <div className="minesweeperPopupAmount">{resultPopup.win ? '+' : ''}₹<span style={{ fontSize: 25 }}>{resultPopup.win ? resultPopup.amount : '0.00'}</span></div>
                            {!resultPopup.win ? (
                                <div className="minesweeperPopupBoard" style={result.board?.length === 16 ? { padding: 7.5, gridTemplateColumns: 'repeat(4, 1fr)', gap: 3 } : {}}>
                                    {result.board?.map((item, index) => {
                                        return (
                                            <div key={index} className={result.checked?.includes(item) ? 'sweeperCheckedResultBoardCol' : 'sweeperResultBoardCol'}>
                                                {result.bomb === item ? (
                                                    <div className="minesweeperBoardCol minesweeperBombBox">
                                                        <img src={img5} width="75%" alt="" />
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div className="minesweeperPopupBoard" style={result.board?.length === 16 ? { padding: 7.5, gridTemplateColumns: 'repeat(4, 1fr)', gap: 3 } : {}}>
                                    {result.board?.map((item, index) => {
                                        return (
                                            <div key={index} className={result.bomb === item ? 'frozenBombCol' : result.checked?.includes(item) ? 'sweeperCheckedResultBoardCol' : 'sweeperResultBoardCol'}>

                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                            <button className="recharge__btn" style={{ width: '80%', height: 45, borderRadius: 7, marginTop: 20 }} onClick={closePopup}>OK</button>
                        </center>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {popup.popup ? (
                <div className="fast__parity__popup">
                    <div className="fast__parity__popup__inner">
                        <div className="fast__parity__popup__nav">
                            <div style={{ flexBasis: 50, width: '100%' }}></div>
                            <div className="fast__parity__popup__nav__col">Minesweeper-{size}X{size}</div>
                            <div style={{ flexBasis: 50, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'right', height: 60, fontSize: 20 }} onClick={() => setPopup({ ...popup, popup: false })}>
                                <IoMdClose />
                            </div>
                        </div>

                        <div className="fast__parity__popup__balance__box">
                            <div className="fast__parity__popup__balance__box__left">
                                <div className="fast__parity__popup__title">Balance</div>
                                <div className="fast__parity__popup__balance__box__left__bottom">₹ {balance}</div>
                            </div>
                            <button className="fast__parity__popup__balance__box__right" onClick={() => window.location.replace('/recharge')}>Recharge</button>
                        </div>

                        <div className="fast__parity__popup__contract__section">
                            <div className="fast__parity__popup__title">Contract Amount</div>
                            <div className="fast__parity__popup__contract__amount__box__container">
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 10 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(10)}>10</div>
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 100 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(100)}>100</div>
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 1000 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(1000)}>1000</div>
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 10000 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(10000)}>10000</div>
                            </div>

                            <div className="fast__parity__popup__contract__amount__input">
                                <button className="fast__parity__popup__contract__amount__input__col" onClick={() => setBetAmount(betAmount - 10)}>-</button>
                                <input type="number" onChange={(e) => setBetAmount(e.target.value)} value={betAmount} />
                                <button className="fast__parity__popup__contract__amount__input__col" onClick={() => setBetAmount(betAmount + 10)}>+</button>
                            </div>

                            {betAmount > 9 ? <button className="parity__bet__btn" onClick={confirmBet}>Confirm</button> : <button className="parity__bet__btn" style={{ background: '#ccc', color: '#555' }}>Confirm</button>}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className="minesweeperNavbar">
                <div className="minesweeperNavCol1">
                    <div className="minesweeperNavIcon">
                        <MdOutlineKeyboardBackspace />
                    </div>
                </div>
                <div className="minesweeperNavCol2">Minesweeper</div>
                <div className="minesweeperNavCol1"></div>
            </div>

            <div className="minesweeperSizeNav">
                <div className="minesweeperSizeNavCol" style={size === 2 ? { background: '#54cfff', color: '#fff' } : { background: '#f0f0f0', color: '#000' }} onClick={() => changeGameSize(2)}>2 X 2</div>
                <div className="minesweeperSizeNavCol" style={size === 4 ? { background: '#54cfff', color: '#fff' } : { background: '#f0f0f0', color: '#000' }} onClick={() => changeGameSize(4)}>4 X 4</div>
            </div>

            <div className="sweeperBoardSection">
                <div className={size === 2 ? "sweeperBoard sweeperBoardSize2" : "sweeperBoard sweeperBoardSize4"}>
                    {board}
                </div>
                {!start ? <div className="minesweeperGameStartSection">
                    <img src="https://res.cloudinary.com/fiewin/image/upload/images/boomStart.png" onClick={() => setPopup({ ...popup, popup: true })} height="35%" alt="" />
                </div> : <></>}
            </div>

            <div className="minesweeperNoteSection">
                {!start ? (
                    <div className="minesweeperNoteBg">
                        <img src="https://res.cloudinary.com/fiewin/image/upload/images/boomCheckTextNew.png" width="100%" alt="" />
                    </div>
                ) : (
                    <div className="minesweeperGameEarning">
                        <div className="minesweeperGameEarningLeft">
                            <div className="sweeperEarningLeftLeft">
                                <div className="sweeperEarningLeftTop">
                                    <img src={img2} height="20" alt="" />
                                    <div>Bonus</div>
                                </div>
                                <div className="sweeperEarningMoney" style={{ fontSize: 18 }}>₹<span style={{ fontSize: 13 }}></span>{game.ATN}</div>
                            </div>
                            <div className="sweeperEarningLeftLeft">
                                <div className="sweeperEarningLeftTop">
                                    <img src={img2} height="20" alt="" />
                                    <div>Next</div>
                                </div>
                                <div className="sweeperEarningMoney" style={{ fontSize: 18 }}>+₹<span style={{ fontSize: 13 }}></span>{game.NCA}</div>
                            </div>
                        </div>
                        <button className="stopGame" onClick={stopGame}>Stop & Claim</button>
                    </div>
                )}
            </div>

            <div className="parity__records__section">
                <div className="parity__records__section__nav__headd"></div>
                <div className="parity__records__section__nav">
                    <div className={orderType ? "parity__records__section__nav__col" : "parity__records__section__nav__col active__parity__record__nav"} onClick={() => setOrderType(false)}>Everyone's Order</div>
                    <div className={!orderType ? "parity__records__section__nav__col" : "parity__records__section__nav__col active__parity__record__nav"} onClick={() => setOrderType(true)}>My Order</div>
                </div>
                {!orderType ? (
                    <div className="parity__user__order__section">
                        <div className="parity__user__order__box__head" style={{ color: '#ccc', fontSize: 12 }}>
                            <div className="sweeperUserGamePeriod">Period</div>
                            <div className="sweeper__user__order__box__user" style={{ textAlign: 'right' }}>User</div>
                            <div className="sweeperUserBoxSelect">Select</div>
                            <div className="sweeperUserGamePoint">Amount</div>
                        </div>
                        {order.map((item, index) => {
                            return (
                                <div className="parity__user__order__box">
                                    <div className="sweeperUserGamePeriod">{item.period}</div>
                                    <div className="sweeper__user__order__box__user">
                                        <div className="sweeperUserOrderDetail">
                                            <div className="sweeperUserOrderPic"></div>
                                            <div className="sweeperUserOrderId">{item.user}</div>
                                        </div>
                                        <div className="sweeperUserGameSize">{item.size}X{item.size}</div>
                                    </div>
                                    <div className="sweeperUserBoxSelect">{item.select}</div>
                                    <div className="sweeperUserGamePoint">{item.amount}</div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className="sweeperOwnOrderSection" style={{ paddingBottom: 20 }}>
                        {myOrder.map((item, index) => {
                            return (
                                <div className="sweeperOwnOrderBox" key={index}>
                                    <div className="sweeperOrderBoxBoard" style={item.size === 2 ? { gap: 4, gridTemplateColumns: 'repeat(2, 1fr)', padding: 2 } : { gap: 2, gridTemplateColumns: 'repeat(4, 1fr)', padding: 1 }}>
                                        {item.board?.map((item2, index2) => {
                                            return (
                                                item.win ? item.bomb !== item2 ? (
                                                    item.checked?.includes(item2) ? (
                                                        <div className="minesweeperCheckedBox"></div>
                                                    ) : (
                                                            <div style={{ background: '#f0f0f0' }}></div>
                                                    )
                                                ) : (
                                                    <div className='frozenBombCol'></div>
                                                ) : item.bomb === item2 ? (
                                                    <div className='minesweeperBombBox'>
                                                        <img src={img5} width="75%" alt="" />
                                                    </div>
                                                ) : item.checked?.includes(item2) ? (
                                                    <div className='minesweeperCheckedBox'></div>
                                                ) : (
                                                    <div style={{ background: '#f5f5f5' }}></div>
                                                )
                                            )
                                        })}
                                    </div>
                                    <div className="sweeperOrderBoxRight">
                                        <div className="sweeperOrderBoxRightTop">
                                            <div className="sweeperOrderBoxRightTopCol">
                                                <div className="sweeperOrderBoxColTop">Point</div>
                                                <div className="sweeperOrderBoxColBottom">₹{item.amount}</div>
                                            </div>
                                            <div className="sweeperOrderBoxRightTopCol">
                                                <div className="sweeperOrderBoxColTop">Pass</div>
                                                <div className="sweeperOrderBoxColBottom">{item.checked?.length}</div>
                                            </div>
                                            <div className="sweeperOrderBoxRightTopCol">
                                                <div className="sweeperOrderBoxColTop">Bonus</div>
                                                <div className="sweeperOrderBoxColBottom">+₹{item.win ? item.ATN?.toFixed(2) : 0}</div>
                                            </div>
                                        </div> <br />
                                        <div className="sweeperOrderBoxRightBottom">
                                            <div className="sweeperOrderBoxColTop">Time: {item.date}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Minesweeper