import React, { useState, useEffect } from 'react'
import './CheckIn.css';
import box from '../../Images/Treasure_b.png'
import chec from '../../Images/Coin.png'
import checPre from '../../Images/Coin_pre.png'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { checkInApi } from './CheckInApi'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toast'
import Loading from '../../Components/Loading'

const CheckIn = () => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState({
        day: "",
        date: ""
    })

    const newDate = new Date();
    const dateF = ("0" + newDate.getFullYear()).slice(-2) + '' + ("0" + newDate.getMonth() + 1).slice(-2) + '' + ("0" + newDate.getDate()).slice(-2)
    const date = parseFloat(dateF)

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/claim`, { id: user })
            .then(response => {
                setData({
                    day: parseFloat(response.data.day),
                    date: parseFloat(response.data.date)
                })

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }


    async function checkIn() {
        let user = localStorage.getItem("user");
        setLoader(true)

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/checkIn`, { id: user }).then((response) => {
            setLoader(false)
            setData({
                day: parseFloat(response.data.day),
                date: parseFloat(response.data.date)
            })

            toast.success('Claimed Successfully')
        }).catch((error) => {
            setLoader(false)
            toast.error(error.response.data?.error)
        })
    }

    useEffect(() => {
        fetchBal()
    }, [])

    return (
        <div className="checkIn__page">
            {loader ? <Loading /> : <></>}
            <div className="checkIn__nav">
                <div className="checkIn__nav__col">
                    <div className="top__nav__back__btn" style={{ color: '#fff'}} onClick={() => window.location.replace('/home')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="checkIn__nav__col" style={{ color: '#fff', fontWeight: 500}}><center>Check In</center></div>
                <div className="checkIn__nav__col"></div>
            </div>

            <div className="checkIn__screen" style={{ position: 'relative' }}>
                <div className="checkIn__screen__container">
                    {checkInApi.map((item, index) => {
                        return (
                            date < data.date || date === data.date ? (
                                data.day === 0 ? (
                                    date === data.date ? (
                                        <div className="checkIn__screen__col" key={index}>
                                            <center>
                                                <div className="checkIn__screen__col__day">Day {item.day}</div>
                                                <img src={chec} alt="" height={40} className="checkIn__screen__col__img" />
                                                <div className="checkIn__screen__col__bonus"><span>+</span> {item.bonus}</div>
                                            </center>
                                        </div>
                                    ) : (
                                        <div className="checkIn__screen__col" key={index}>
                                            <center>
                                                <div className="checkIn__screen__col__day">Day {item.day}</div>
                                                <img src={checPre} alt="" height={40} className="checkIn__screen__col__img" />
                                                <div className="checkIn__screen__col__bonus"><span>+</span> {item.bonus}</div>
                                            </center>
                                        </div>
                                    )
                                ) : (
                                    <div className="checkIn__screen__col" key={index}>
                                        <center>
                                            <div className="checkIn__screen__col__day">Day {item.day}</div>
                                            <img src={data.day < item.day ? chec : checPre} alt="" height={40} className="checkIn__screen__col__img" />
                                            <div className="checkIn__screen__col__bonus"><span>+</span> {item.bonus}</div>
                                        </center>
                                    </div>
                                )
                            ) : (
                                <div className="checkIn__screen__col" key={index}>
                                    <center>
                                        <div className="checkIn__screen__col__day">Day {item.day}</div>
                                        <img src={chec} alt="" height={40} className="checkIn__screen__col__img" />
                                        <div className="checkIn__screen__col__bonus"><span>+</span> {item.bonus}</div>
                                    </center>
                                </div>
                            )
                        )
                    })}
                    
                </div>
                <div className="checkIn__button__container">
                    {date === parseFloat(data.date) || date > data.date ? <button className="checkIn__button" style={{ background: '#fddd0c', color: '#fff' }} onClick={checkIn}>Check In</button> : <button className="checkIn__button">Check In</button>}
                </div>
            </div>

            <div className="checkIn__bottom__screen">
                <div className="checkIn__bottom__screen__text">Check in for 7 consecutive days to get the key, use the key treasure box, and receive mysterious prizes!</div>
                <div className="checkIn__bottom__screen__img">
                    <center>
                        <img src={box} alt="" className="img1" />
                    </center>
                </div>
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default CheckIn