import React, { useState, useEffect } from 'react'
import './Invite.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toast'
import Loading from '../../Components/Loading'
import copy from "copy-to-clipboard";  
import picImg from '../../Images/defaultAvatar.png'
import mainImg from '../../Images/redPacketNormal.png'

const Lifafa = () => {
    const [loader, setLoader] = useState(true)
    const [mobile, setMobile] = useState("")
    const [id, setId] = useState("")
    const [data, setData] = useState({
        a: "0",
        b: "0"
    })

    const query = new URLSearchParams(window.location.search);
    const a = query.get('id')

    async function claim() {
        let user = localStorage.getItem("user");
        setLoader(true)

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/claimLifafa`, { user: user, id: a }).then(response3 => {
            setLoader(false)
            toast.success(response3.data.message)
        }).catch(error => {
            setLoader(false)
            toast.error(error.response.data?.error)
        })
    }

    async function fetchData() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetchMob`, { user: user }).then(response => {
            setMobile(response.data.mob);
            setId(response.data.id)

            axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetchLifafa`, { user: user, id: a }).then(response2 => {
                setLoader(false)
                setData({
                    ...data,
                    a: response2.data.claimed,
                    b: response2.data.amount,
                })
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
        }).catch(error => {
            setLoader(false)
            toast.error(error.response.data?.error)
        })
    }

    function copyLink() {
        copy(window.location.origin + '/register?ref=' + id)
        toast.success('Link Copied')
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            {loader ? <Loading /> : <></>}

            <div className="lifafaPageTop">
                <img src={mainImg} width="100%" alt="" />
            </div>
            <div className="lifafaUserBoxContainer">
                <div className="lifafaUserBoxTop">
                    <div className="lifafaUserBoxUnder">New user's Lucky Rupees</div>
                </div>
                <div className="lifafaUserBox">
                    <div className="lifafaUserView">
                        <div className="lifafaUserPic">
                            <img src={picImg} height='60' alt="" />
                        </div>
                        <div className="lifafaUserMob">{mobile}</div>
                    </div>
                    <button className="lifafaFirstBtn" onClick={claim}>Get It Now</button>
                    <div className="lifafaButtonBelowField">
                        <input type="text" style={{ padding: "0 15px"}} value={window.location.origin + '/register?ref=' + id} />
                        <button className="lifafaSecondBtn" onClick={copyLink}>Copy</button>
                    </div>
                    <center>
                        <div className="lifafaSillyText">Share the link to get commission from the invitee</div>
                    </center>
                </div>
            </div>
            <center>
                <div className="lifafaAmountSupplied">Received ₹{data.a} / Total ₹{data.b}</div>
            </center>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Lifafa