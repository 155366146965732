import React from 'react'
import './Recharge.css'
import { IoClose } from 'react-icons/io5'
import axios from 'axios'

const RechargeScreen = ({ amount }) => {
    async function paid() {
        var id = prompt("Enter your transaction order id:", "");
        var nId = localStorage.getItem("user")

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/deposit`, { id: nId, tid: id})
            .then(response => {
                alert("Deposit done successful")
            }).catch(error => {
                alert(error.response.data?.error)
            })
    }

    const upi = encodeURIComponent('upi://pay?pa=paytmqr2810050501011c4ariv4ruvd@paytm&pn=Mr Harsh Jha&am=' + amount + '&cu=INR&tn=fiewin deposit')
    return (
        <div className='deposit__screen'>
            <div className='app__top__nav' style={{ background: 'transparent', borderBottom: '1px solid #ccc' }}>
                <div className="top__nav__cols">
                    <div className="top__nav__back__btn" onClick={() => window.location.reload()} style={{ color: '#fff' }}>
                        <IoClose />
                    </div>
                </div>
                <div className="top__nav__cols">
                    <center>
                        <div className="top__nav__title" style={{ color: '#fff' }}>Recharge</div>
                    </center>
                </div>
                <div className="top__nav__cols"></div>
            </div>

            <div className="deposit__qr__screen">
                <center>
                    <div className="deposit__qr__container">
                        <img src={`https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${upi}&chld=H|3`} width="265" alt="" className="deposit__qr__img" /> <br />
                    </div>
                </center>
            </div>

            <div className="deposit__notes">
                <div className="notes__head">Recharge Steps:</div>
                <div className="notes__body">1. Open your favorite payment app and scan or upload the above QR and make payment. <br />2. After successful payment copy the order id & comback to our site. <br />3. Click on <b>Paid</b> button, you will be asked for order id, enter the id and click on <b>confirm</b> button.</div>
            </div>

            <div className="deposit__screen__btn">
                <button onClick={() => paid()}>Paid</button>
            </div>
        </div>
    )
}

export default RechargeScreen