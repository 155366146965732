import React from 'react'
import './BottomBar'
import { IoHomeOutline, IoHome, IoDownloadOutline, IoDownload } from 'react-icons/io5'
import { RiGroupLine, RiGroupFill, RiUserLine, RiUserFill } from 'react-icons/ri'

const BottomBar = ({ page }) => {
    return (
        <div className='BottomBar__screen'>
            {page === 1 ? (
                <div className="bottomBar__col">
                    <center>
                        <div className="bottomBar__col__icon" style={{ color: '#5f5ff8' }}>
                            <IoHome />
                        </div>
                        <div className="bottomBar__col__name" style={{ color: '#5f5ff8' }}>Home</div>
                    </center>
                </div>
            ) : (
                <div className="bottomBar__col" onClick={() => window.location.replace('/')}>
                    <center>
                        <div className="bottomBar__col__icon" style={{ color: '#777' }}>
                            <IoHomeOutline />
                        </div>
                        <div className="bottomBar__col__name" style={{ color: '#777' }}>Home</div>
                    </center>
                </div>
            )}

            {page === 2 ? (
                <div className="bottomBar__col">
                    <center>
                        <div className="bottomBar__col__icon" style={{ color: '#5f5ff8' }}>
                            <RiGroupFill />
                        </div>
                        <div className="bottomBar__col__name" style={{ color: '#5f5ff8' }}>Invite</div>
                    </center>
                </div>
            ) : (
                <div className="bottomBar__col" onClick={() => window.location.replace('/invite')}>
                    <center>
                        <div className="bottomBar__col__icon" style={{ color: '#777' }}>
                            <RiGroupLine />
                        </div>
                        <div className="bottomBar__col__name" style={{ color: '#777' }}>Invite</div>
                    </center>
                </div>
            )}

            {page === 3 ? (
                <div className="bottomBar__col">
                    <center>
                        <div className="bottomBar__col__icon" style={{ color: '#5f5ff8' }}>
                            <IoDownload />
                        </div>
                        <div className="bottomBar__col__name" style={{ color: '#5f5ff8' }}>Recharge</div>
                    </center>
                </div>
            ) : (
                <div className="bottomBar__col" onClick={() => window.location.replace('/recharge')}>
                    <center>
                        <div className="bottomBar__col__icon" style={{ color: '#777' }}>
                            <IoDownloadOutline />
                        </div>
                        <div className="bottomBar__col__name" style={{ color: '#777' }}>Recharge</div>
                    </center>
                </div>
            )}

            {page === 4 ? (
                <div className="bottomBar__col">
                    <center>
                        <div className="bottomBar__col__icon" style={{ color: '#5f5ff8' }}>
                            <RiUserFill />
                        </div>
                        <div className="bottomBar__col__name" style={{ color: '#5f5ff8' }}>My</div>
                    </center>
                </div>
            ) : (
                <div className="bottomBar__col" onClick={() => window.location.replace('/my')}>
                    <center>
                        <div className="bottomBar__col__icon" style={{ color: '#777' }}>
                            <RiUserLine />
                        </div>
                        <div className="bottomBar__col__name" style={{ color: '#777' }}>My</div>
                    </center>
                </div>
            )}
        </div>
    )
}

export default BottomBar