import React, { useState, useEffect } from 'react'
import './Invite.css'
import axios from 'axios'
import BottomBar from '../../Components/BottomBar'
import { ToastContainer, toast } from 'react-toast'
import { useNavigate } from 'react-router-dom'
import copy from "copy-to-clipboard";  
import Loading from '../../Components/Loading'
import img1 from '../../Images/Invite/PrivilegeNew1.png'
import img2 from '../../Images/Invite/rankingIconNew1.png'
import img3 from '../../Images/Invite/linkNew1.png'
import img4 from '../../Images/Invite/agentPlanEnter.png'
import img5 from '../../Images/Invite/goRight.png'

const Invite = () => {
  const [id, setId] = useState("")
  const [balance, setBalance] = useState(0)
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true)
  const [data2, setData2] = useState({
    a: 0,
    b: 0,
    c: 0,
    d: 0
  })

  const navigate = useNavigate();

  async function fetchBal() {
    let user = localStorage.getItem("user");

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/id`, { id: user })
      .then(response => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/balance`, { id: user })
          .then(response2 => {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetchRefDetail`, { id: user }).then(response3 => {
              setLoader(false)
              setId(response.data?.id)
              setBalance(response2.data?.referral);
              setData(response3.data.data) 
              setData2({
                a: response3.data.todayInv,
                b: response3.data.todayInc,
                c: response3.data.totalInv,
                d: response3.data.totalInc
              })
            }).catch(error => {
              setLoader(false)
              toast.error(error.response.data?.error)
            })
          }).catch(error => {
            setLoader(false)
            toast.error(error.response.data?.error)
          })
      }).catch(error => {
        setLoader(false)
        toast.error(error.response.data?.error)
      })
  }

  function copyLink() {
    copy(window.location.origin + '/register?ref=' + id)
    toast.success('Link Copied')
  }

  useEffect(() => {
    fetchBal()
  }, [])

  return (
    <div style={{ minHeight: '100vh', background: '#fff' }}>
      {loader ? <Loading /> : <></>}
      <BottomBar page={2} />
      <div className="invite__page__nav" style={{ color: '#fff', fontWeight: 500 }}>Invite & Earn</div>
      <div style={{ height: 50, width: '100%'}}></div>
      <div className="invite__page__top">
        <div className="invite__balance__section">
          <div className="invite__balance__section__box">
            <div className="invite__balance__section__box__left">
              <div className="invite__balance__section__box__left__top">Referral Income</div>
              <div className="invite__balance__section__box__left__bottom" style={{ fontFamily: 'sans-serif' }}>₹{balance}</div>
            </div>
            <button className="referral__balance__transfer__btn" onClick={() => navigate('/withdraw?type=2')}>Withdraw</button>
          </div>
        </div>
        <div className="invitePageMenuOptions">
          <div className="invitePageMenuCol" onClick={() => navigate('/privilege')}>
            <img src={img1} width={'100%'} alt="" />
          </div>
          <div className="invitePageMenuCol" onClick={() => navigate('/ranking')}>
            <img src={img2} width={'100%'} alt="" />
          </div>
          <div className="invitePageMenuCol" onClick={() => navigate('/InviteLink')}>
            <img src={img3} width={'100%'} alt="" />
          </div>
        </div>
      </div>
      <div className="invitePageBottom">
        <div className="invitePageBottomTop" onClick={() => navigate('/agentPlan')}>
          <img src={img4} width="100%" alt="" />
        </div>
        <div className="invitePageBottomBottom">
          <div className="invitePageBottomBottomCols">
            <center>
              <div className="invitePageBottomColTop">Invited Today</div>
              <div className="invitePageBottomColBottom">{data2.a}</div>
              <div className="invitePageMostBottom" onClick={() => navigate('/team')}>
                <div className="invitePageMostBottomLeft">Total {data2.c}</div>
                <img src={img5} height={17.5} alt="" />
              </div>
            </center>
          </div>
          <div className="invitePageBottomBottomCols">
            <center>
              <div className="invitePageBottomColTop">Today's Income</div>
              <div className="invitePageBottomColBottom">₹{data2.b}</div>
              <div className="invitePageMostBottom" onClick={() => navigate('/daily-rec')}>
                <div className="invitePageMostBottomLeft">Total ₹{data2.d}</div>
                <img src={img5} height={17.5} alt="" />
              </div>
            </center>
          </div>
        </div>
      </div>
      <div className="invitePageBreaker"></div>
      <div className="invitePageIncomeDetails">
        <div className="invitePageIncomeDetailsTop">
          <div className="invitePageIncomeDetailsTopLeft">Income Detail's</div>
          <div className="invitePageIncomeDetailsTopRight" onClick={() => navigate('/commission')}>More ></div>
        </div>
        <div className="invitePageIncomeDetailsBottom">
          {data.length === 0 ? (
            <div className="orderRecordSillyText">No Record Found!</div>
          ):(
            data.map((item, index) => {
              return (
                <div className="invitePageIncomeBox" key={index}>
                  <div className="invitePageIncomeBoxLeft">
                    <div className="invitePageIncomeBoxIcon">
                      <img src={item.image} width="100%" style={{ borderRadius: 100}} alt="" />
                    </div>
                    <div className="invitePageIncomeBoxLeftRight">
                      <div className="invitePageIncomeDetail1">{item.title}</div>
                      <div className="invitePageIncomeDetailBottom">
                        <div className="invitePageIncomeTime">{item.time} &nbsp;&nbsp;&nbsp; from {item.user}</div>
                      </div>
                    </div>
                  </div>
                  <div className="invitePageIncomeAmount">+₹{item.commission}</div>
                </div>
              )
            })
          )}
        </div>
      </div>

      <ToastContainer delay={3000} position={'top-center'} />
    </div>
  )
}

export default Invite