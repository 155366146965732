import React, { useState } from 'react'
import './Invite.css'
import { useNavigate } from 'react-router-dom'
import img from '../../Images/photo_2023-01-25_12-59-11.jpg'
import { Slider } from '@mui/material'
import img2 from '../../Images/Invite/Agency02.png'
import img3 from '../../Images/Invite/Agency03.png'
import img4 from '../../Images/Invite/Agency04.png'
import img5 from '../../Images/Invite/Agency05.png'
import img6 from '../../Images/Invite/photo_2023-03-16_01-58-10.jpg'

const Privilege = () => {
  const navigate = useNavigate()

  const [val, setVal] = useState(50)
  const [val2, setVal2] = useState(50)
  const [val3, setVal3] = useState(50)

  return (
    <div>
      <div className="privilegeTop">
        <img src={img} width="100%" alt="" />
        <div className="privilegeBackButton" onClick={() => navigate(-1)}>
          <img src="https://res.cloudinary.com/fiewin/image/upload/images/backWhite.png" height={22.5} alt="" />
        </div>
      </div>
      <div className="privilegeTopDown">
        <img src={img2} width="100%" alt="" />
        <div className="privilegeCalculatorContainer">
          <div className="privilegeCalculator">
            <div className="privilegeCalculatorHead">Commission estimate</div>
            <div className="privilegeCalculatorBottom">
              <div className="privilegeBottomLeft">People({val})</div>
              <div className="privilegeBottomRight">
                <Slider onChange={(e) => setVal(e.target.value)} min={1} max={100} value={val} area-label='Default' valueLabelDisplay='auto' />
              </div>
            </div>
            <div className="privilegeCalculatorBottom" style={{ marginTop: -15}}>
              <div className="privilegeBottomLeft">Days({val2})</div>
              <div className="privilegeBottomRight">
                <Slider onChange={(e) => setVal2(e.target.value)} min={1} max={100} value={val2} area-label='Default' valueLabelDisplay='auto' />
              </div>
            </div>
            <center>
              <div className="privilegeCalculated">Commission: ₹{(val2 * 62) * val}</div>
            </center>
          </div>
        </div>
      </div>
      <div className="privilegeTopDown2">
        <img src={img3} width="100%" alt="" />
        <div className="privilegeCalculatorContainer" style={{ marginTop: -170}}>
          <div className="privilegeCalculator">
            <div className="privilegeCalculatorHead">Income estimate</div>
            <div className="privilegeCalculatorBottom">
              <div className="privilegeBottomLeft">People({val3})</div>
              <div className="privilegeBottomRight">
                <Slider onChange={(e) => setVal3(e.target.value)} min={1} max={100} value={val3} area-label='Default' valueLabelDisplay='auto' />
              </div>
            </div>
            <center>
              <div className="privilegeCalculated">Reward: ₹{val3 * 50}</div>
            </center>
          </div>
        </div>
      </div>
      <div className="privilegeTopDown3">
        <img src={img4} width="100%" alt="" />
      </div>
      <div className="privilegeTopDown3">
        <img src={img5} width="100%" alt="" />
      </div>
      <div className="privilegeTopDown3">
        <img src={img6} width="100%" alt="" />
      </div>
    </div>
  )
}

export default Privilege