import React, { useState } from 'react'
import './Auth.css'
import img from '../../Images/login.png'
import { IoKeyOutline } from 'react-icons/io5'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { ImMobile } from 'react-icons/im'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toast'

const Login = () => {
    const [user, setUser] = useState({
        phoneNumber: "",
        password: ""
    })
    const [view, setView] = useState(false)

    async function login() {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, { phoneNumber: user.phoneNumber, password: user.password })
            .then(response => {
                localStorage.setItem("user", response.data.user);
                window.location.replace('/')
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    return (
        <div className='auth__page'>
            <center>
                <div className="auth__screen">
                    <div className="auth__screen__logo" style={{ marginTop: 50 }}>
                        <img src={img} height={45} alt="" />
                    </div>
                </div>

                <div className="auth__form" style={{ paddingTop: 75 }}>
                    <div className="auth__form__input">
                        <div className="auth__form__input__left" style={{ fontSize: 23}}><ImMobile /></div>+91
                        <input type="number" value={user.phoneNumber} onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })} placeholder='Enter Mobile Number' />
                    </div>
                    <div className="auth__form__input input__margin">
                        <div className="auth__form__input__left">
                            <IoKeyOutline style={{ fontSize: 22 }} />
                        </div>
                        <input type={view ? "text" : "password"} placeholder='Enter Password' value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} />
                        <div className="auth__form__input__left" style={{ background: 'transparent', color: '#777' }}>
                            {view ? <BsFillEyeSlashFill style={{ fontSize: 22 }} onClick={() => setView(false)} /> : <BsFillEyeFill style={{ fontSize: 22 }} onClick={() => setView(true)} />}
                        </div>
                    </div>
                    {user.phoneNumber?.length === 10 && user.password?.length > 5 ? <button className="login__btn" style={{ background: 'rgb(0, 147, 255)', color: '#fff' }} onClick={login}>Login</button> : <button className="login__btn">Login</button>}
                    <div className='login__span'>Don't have an account? <a href="/register">Register</a></div>
                </div>
            </center>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Login