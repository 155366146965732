import React, { useState, useEffect } from 'react'
import './Invite.css'
import Loading from '../../Components/Loading'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { ToastContainer, toast } from 'react-toast'

const Ranking = () => {
    const [loader, setLoader] = useState(false)

    return (
        <div>
            {loader ? <Loading /> : <></>}
            <div className="checkIn__nav" style={{ background: '#117090', color: '#fff', borderBottom: '1px solid #aaa', height: 40 }}>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}>
                    <div className="top__nav__back__btn" onClick={() => window.location.replace('/invite')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="checkIn__nav__col" style={{ flexBasis: 'calc(100% - 100px)', width: '100%', fontWeight: 500 }}><center>Ranking Rewards</center></div>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}></div>
            </div>

            <div className="rankingPage__top">
                <div className="rankingPage__top__inner">
                    <center style={{ width: '100%'}}>
                        <div className="rankingPage__top__text">TOP20 Rewards</div>
                        <div className="rankingPage__top__timer">
                            <div className="rankingPage__top__timer__cols__container">
                                <div className="rankingPage__top__timer__cols">0</div>
                                <div className="rankingPage__top__timer__cols" style={{ marginLeft: 4}}>2</div>
                            </div>
                            <div className="rankingPage__top__timer__col2">:</div>
                            <div className="rankingPage__top__timer__cols__container">
                                <div className="rankingPage__top__timer__cols">1</div>
                                <div className="rankingPage__top__timer__cols" style={{ marginLeft: 4}}>3</div>
                            </div>
                            <div className="rankingPage__top__timer__col2">:</div>
                            <div className="rankingPage__top__timer__cols__container">
                                <div className="rankingPage__top__timer__cols">4</div>
                                <div className="rankingPage__top__timer__cols" style={{ marginLeft: 4}}>5</div>
                            </div>
                        </div>
                        <div className="rankingPage__bottom__text">Rewards will be given at 21:30 everyday</div>
                    </center>
                </div>
            </div>

            <div className="rankingPage__top3Screen">
                <div className="rankingUserType1"></div>
                <div className="rankingUserType2"></div>
                <div className="rankingUserType1"></div>
            </div>

            <div className="rankingPage__bottom">
                <div className="rankingPage__bottom__col">
                    <div className="rankingPage__bottom__col__left">
                        <div className="rankingPage__userRank">4</div>
                        <div className="rankingPage__bottom__col__left__left">
                            <div className="rankingPage__userPic"></div>
                            <div className="rankingPage__bottom__col__left__left__left">
                                <div className="rankingPage__userId">ID: 0004</div>
                                <div className="rankingPage__userIncome">Income: ₹3936.788</div>
                            </div>
                        </div>
                    </div>

                    <div className="rankingPage__bottom__col__right">+ ₹4011</div>
                </div>
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Ranking