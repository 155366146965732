import React, { useState, useEffect } from 'react'
import './Withdrawal.css';
import { IoIosArrowRoundBack } from 'react-icons/io'
import { GiCheckMark } from 'react-icons/gi'
import { FaRupeeSign } from 'react-icons/fa'
import axios from 'axios'
import Loading from '../../Components/Loading'
import { ToastContainer, toast } from 'react-toast'

const Withdrawal = () => {
    const query = new URLSearchParams(window.location.search);

    const [loader, setLoader] = useState(true)
    const [t, setT] = useState(parseFloat(query.get('type')) === 2 ? false : true)
    const [balance, setBalance] = useState("0")
    const [amount, setAmount] = useState("")
    const [account, setAccount] = useState(null)
    const [card, setCard] = useState(false)
    const [data, setData] = useState(null);
    const [isData, setIsData] = useState(false);

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/balance`, { id: user })
            .then(response => {
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/account`, { id: user })
                    .then(response2 => {
                        axios.post(`${process.env.REACT_APP_BACKEND_URL}/withdrawalRecords`, { id: user }).then((response3) => {
                            setAccount(response2.data)
                            setBalance(t ? response.data.withdraw : response.data.referral)
                            setLoader(false)

                            if (response3.data.records) {
                                setIsData(true)
                                setData(response3.data.data)
                            }

                            if (response2.data.active) {
                                setCard(true)
                            }
                        }).catch(error => {
                            toast.error(error.response.data?.error)
                            setLoader(false)
                        })
                    }).catch(error => {
                        toast.error(error.response.data?.error)
                        setLoader(false)
                    })
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }

    async function withdraw() {
        setLoader(true)
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/withdraw`, { id: user, amount: parseFloat(amount), type: t }).then((response) => {
            fetchBal()
        }).catch(error => {
            setLoader(false);
            toast.error(error.response.data?.error)
        })
    }

    useEffect(() => {
        fetchBal()
    }, [])

    return (
        <div style={{ minHeight: '100vh', background: '#fff' }}>
            {loader ? <Loading /> : <></>}

            <div className="checkIn__nav" style={{ borderBottom: '1px solid #f0f0f0', height: 40 }}>
                <div className="checkIn__nav__col">
                    <div className="top__nav__back__btn" onClick={() => window.location.replace('/home')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="checkIn__nav__col"><center>Withdrawal</center></div>
                <div className="checkIn__nav__col"></div>
            </div>

            <div className="withdrawal__page__balance__section">
                <center>
                    <div className="withdrawal__page__balance__section__top">My Balance</div>
                    <div className="withdrawal__page__balance__section__bottom" style={{ fontFamily: 'sans-serif' }}>₹{balance}</div>
                </center>
            </div>

            <div className="passbook__details">
                {!account || account.active === false ? (
                    <div className="passbook__detail__box" onClick={() => window.location.replace('/paymentMethods')}>
                        <center>
                            <div className="passbook__detail__box__type2">Add Payment Method</div>
                        </center>
                    </div>
                ) : (
                    <div className="passbook__details__in">
                        <div className="passbook__detail__box" style={{ marginTop: 15 }}>
                            <div className="to__bank">{account.isBank ? "Bank" : "UPI"}</div>
                            <div className="passbook__active__container">
                                <div className="passbook__active">
                                    <GiCheckMark />
                                </div>
                            </div>


                            {account.isBank ? (
                                <div className="passbook__detail">
                                    <div className="passbook__detail__col">
                                        <div className="passbook__detail__col__left">Name</div>
                                        <div className="passbook__detail__col__right">{account.name}</div>
                                    </div>
                                    <div className="passbook__detail__col">
                                        <div className="passbook__detail__col__left">IFSC</div>
                                        <div className="passbook__detail__col__right">{account.ifsc?.toUpperCase()}</div>
                                    </div>
                                    <div className="passbook__detail__col">
                                        <div className="passbook__detail__col__left">Account Number</div>
                                        <div className="passbook__detail__col__right">{account.account}</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="passbook__detail">
                                    <center>
                                        <div className="passbook__detail__type__10" style={{ marginTop: 0 }}>{account.name}</div>
                                        <div className="passbook__detail__type__10">{account.upi}</div>
                                    </center>
                                </div>
                            )}
                        </div>
                        <div className="changeCard" onClick={() => window.location.replace('/paymentMethods')}>change ></div>
                    </div>
                )}
            </div>

            <div className="withdrawal__amount__field">
                <div className="withdrawal__field__header">Withdrawal Amount <span style={{ fontSize: 12, fontWeight: 300 }}></span></div>
                <div className="withdrawal__input__field">
                    <div className="withdrawal__input__field__icon">
                        <FaRupeeSign />
                    </div>
                    <input type="number" placeholder="100 - 50000" value={amount} onChange={(e) => setAmount(e.target.value)} name="" id="" />
                </div>
                {t ? (
                    <>
                        <div className="withdrawal__input__notes">
                            <div className="withdrawal__input__notes__left">Amount &lt;₹1500,fee 30</div>
                            <div className="withdrawal__input__notes__left">Maximum: ₹{balance}</div>
                        </div>
                        <div className="withdrawal__input__notes">
                            <div className="withdrawal__input__notes__left">Amount &gt;=₹1500,fee 2%</div>
                            <div className="withdrawal__input__notes__left">Minimum: ₹35</div>
                        </div>
                    </>
                ):(
                        <div className="withdrawal__input__notes">
                            <div className="withdrawal__input__notes__left">Minimum: ₹31</div>
                            <div className="withdrawal__input__notes__left">Maximum: ₹{balance}</div>
                        </div>
                )}
                <br />
                {card === true && parseFloat(amount) >= 100 ? <button className="withdraw__btn" style={{ background: '#5f5ff8', height: 45 }} onClick={withdraw}>Withdraw</button> : <button className="withdraw__btn" style={{ background: '#ccc', color: '#666', height: 45 }}>Withdraw</button>}
            </div>

            <div className="withdrawal__records__section">
                <div className="withdrawal__records__section__record__top"></div>
                <div className="withdrawal__records__section__bottom">
                    <div className="withdrawal__records__section__bottom__header">Withdrawal Records</div>
                    {isData ? (
                        data.map((item, index) => {
                            return (
                                <div className="withdrawalRecords__container" key={index}>
                                    <div className="withdrawalRecords__container__box">
                                        <div className="withdrawalRecords__container__box__top">
                                            <div className="withdrawalRecords__container__box__top__col" style={{ flexBasis: '32%', width: '100%' }}>
                                                <div className="withdrawalRecords__container__box__top__top">Amount</div>
                                                <div className="withdrawalRecords__container__box__top__bottom" style={{ fontFamily: 'sans-serif' }}>₹{item.amount + item.fee}</div>
                                            </div>
                                            <div className="withdrawalRecords__container__box__top__col" style={{ flexBasis: '34%', width: '100%' }}>
                                                <div className="withdrawalRecords__container__box__top__top">Time</div>
                                                <div className="withdrawalRecords__container__box__top__bottom">{item.date}</div>
                                            </div>
                                            <div className="withdrawalRecords__container__box__top__col" style={{ flexBasis: '34%', width: '100%', textAlign: 'right' }}>
                                                <div className="withdrawalRecords__container__box__top__top">Status</div>
                                                <div className="withdrawalRecords__container__box__top__bottom">{item.status}</div>
                                            </div>
                                        </div>

                                        <div className="withdrawalRecords__container__box__bottom">
                                            <div className="withdrawalRecords__container__box__bottom__top">
                                                <div className="withdrawalRecords__container__box__bottom__top__col">Actually Arrived: {item.amount}</div>
                                                <div className="withdrawalRecords__container__box__bottom__top__col">Fee: {item.fee}</div>
                                            </div>
                                            <div className="withdrawalRecords__container__box__bottom__top" style={{ marginTop: 12 }}>
                                                <div className="withdrawalRecords__container__box__bottom__top__col">Name:</div>
                                                <div className="withdrawalRecords__container__box__bottom__top__col">Harsh Kumar Jha</div>
                                            </div>
                                            {item.isBank ? (
                                                <>
                                                    <div className="withdrawalRecords__container__box__bottom__top">
                                                        <div className="withdrawalRecords__container__box__bottom__top__col">IFSC:</div>
                                                        <div className="withdrawalRecords__container__box__bottom__top__col">{item.ifsc.toUpperCase()}</div>
                                                    </div>
                                                    <div className="withdrawalRecords__container__box__bottom__top">
                                                        <div className="withdrawalRecords__container__box__bottom__top__col">A/C Number:</div>
                                                        <div className="withdrawalRecords__container__box__bottom__top__col">{item.account}</div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="withdrawalRecords__container__box__bottom__top">
                                                        <div className="withdrawalRecords__container__box__bottom__top__col">UPI:</div>
                                                        <div className="withdrawalRecords__container__box__bottom__top__col">{item.upi}</div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className="withdrawal__records__container">No Records Found</div>
                    )}
                </div>
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div >
    )
}

export default Withdrawal