import React, { useState, useEffect } from 'react'
import './Task.css';
import { IoIosArrowRoundBack } from 'react-icons/io'
import axios from 'axios'
import Loading from '../../Components/Loading';
import { ToastContainer, toast } from 'react-toast'

const Task = () => {
    const [mine, setMine] = useState(null)
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState({
        recharge: false,
        invite: false,
        order: 0
    })

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/getTask`, { id: user })
            .then(response => {
                setData({
                    ...data,
                    recharge: response.data.deposit,
                    invite: response.data.invite,
                    order: response.data.order
                })

                setLoader(false)
                setMine(response.data.task)
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }

    async function claim(id) {
        setLoader(true)
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/claimTask`, { id: user, task: id })
            .then(response => {
                fetchBal()
                toast.success('Claimed Successfully')
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBal()
    }, [])

    const task = [
        {
            taskId: 'TASK0001',
            icon: 'https://res.cloudinary.com/fiewin/image/upload/images/rechargeOrder.png',
            discription: 'You can receive it after completing the first recharge',
            title: 'First Recharge',
            amount: 5,
            claimed: mine && mine.TASK0001 ? true : false,
            button_txt: 'Go to recharge',
            task_status: data.recharge === true ? 100 : 0,
            route: '/recharge'
        },
        {
            taskId: 'TASK0002',
            icon: 'https://res.cloudinary.com/fiewin/image/upload/images/Effective.png',
            discription: 'After inviting users to complete download registration and purchase points, they can receive',
            title: 'First Invitation',
            amount: 5,
            button_txt: 'Go to invite',
            task_status: data.invite === true ? 100 : 0,
            claimed: mine && mine.TASK0002 ? true : false,
            route: '/invite'
        },
        {
            taskId: 'TASK0003',
            icon: 'https://res.cloudinary.com/fiewin/image/upload/images/task100orders.png',
            discription: 'All game orders, more than 100 times',
            amount: 20,
            button_txt: 'Go order',
            task_status: data.order >= 100 ? 100 : ((data.order / 100) * 100),
            claimed: mine && mine.TASK0003 ? true : false,
            title: 'More than 100 orders',
            route: '/game/fastParity'
        },
        {
            taskId: 'TASK0004',
            icon: 'https://res.cloudinary.com/fiewin/image/upload/images/task1000orders.png',
            discription: 'All game orders, more than 1000 times',
            amount: 100,
            button_txt: 'Go order',
            task_status: data.order >= 1000 ? 100 : ((data.order / 1000) * 100),
            claimed: mine && mine.TASK0004 ? true : false,
            title: 'More than 1000 orders',
            route: '/game/fastParity'
        },
        {
            taskId: 'TASK0005',
            icon: 'https://res.cloudinary.com/fiewin/image/upload/images/task10000orders.png',
            discription: 'All game orders, more than 10000 times',
            amount: 1000,
            button_txt: 'Go order',
            task_status: data.order >= 10000 ? 100 : ((data.order / 10000) * 100),
            claimed: mine && mine.TASK0005 ? true : false,
            title: 'More than 10000 orders',
            route: '/game/fastParity'
        }
    ]

    return (
        <div style={{ minHeight: '100vh'}}>
            {loader ? <Loading /> : <></>}
            <div className="checkIn__nav" style={{ background: '#fff', height: 40 }}>
                <div className="checkIn__nav__col">
                    <div className="top__nav__back__btn" onClick={() => window.location.replace('/home')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="checkIn__nav__col"><center>Task</center></div>
                <div className="checkIn__nav__col"></div>
            </div>

            <div className="task__screen">
                {task.map((item, index) => {
                    return (
                        <div className="task__box" key={index}>
                            <div className="task__box__top">
                                <div className="task__box__top__top">
                                    <div className="task__box__top__top__left">
                                        <div className="task__img">
                                            <img src={item.icon} height="100%" alt="" />
                                        </div>
                                        <div className="task__box__top__top__left__right__top">
                                            <div className="task__title">{item.title}</div>
                                            <div className="task__discription">{item.discription}</div>
                                        </div>
                                    </div>
                                    <div className="task__prize">₹{item.amount}</div>
                                </div>
                                <div className="task__meter">
                                    <div className="task__parameter" style={{ width: `${item.task_status}%`}}></div>
                                </div>
                                <div className="task__meter__bottom">
                                    <div className="task__meter__bottom__left">0%</div>
                                    <div className="task__meter__bottom__right">100%</div>
                                </div>

                                {item.claimed ? <button className="task__button" style={{ background: '#ccc', color: '#666', border: '2px solid #ccc'}}>Claimed</button> : item.task_status === 100 ? <button className="task__button" onClick={() => claim(item.taskId)}>Claim Now</button> : <button className="task__button" onClick={() => window.location.replace(item.route)}>{item.button_txt}</button>}
                            </div>

                            <div className="task__box__bottom"></div>
                        </div>
                    )
                })}
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Task