import React, { useState, useEffect } from 'react'
import './Invite.css'
import { useNavigate } from 'react-router-dom'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { saveAs } from 'file-saver'
import { ToastContainer, toast } from 'react-toast'
import copy from "copy-to-clipboard";  
import axios from 'axios'
import img1 from '../../Images/Invite/Banner/20230216_212433.png'
import img2 from '../../Images/Invite/Banner/20230216_213140.png'
import img3 from '../../Images/Invite/Banner/20230216_215105.png'

const InviteLink = () => {
    const navigate = useNavigate()
    const [id, setId] = useState('')

    function saveImg(prop) {
        saveAs(prop, 'image.png')
        toast.success('Images saved successfully')
    }

    async function fetchD() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/id`, { id: user }).then((response) => {
            setId(response.data?.id)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchD()
    }, [])

    function copyLink() {
        copy(window.location.origin + '/register?ref=' + id)
        toast.success('Link Copied')
    }
    return (
        <div style={{ minHeight: '100vh', background: '#fff'}}>
            <div className="checkIn__nav" style={{ borderBottom: '1px solid #f0f0f0', height: 40 }}>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}>
                    <div className="top__nav__back__btn" onClick={() => navigate(-1)}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="checkIn__nav__col" style={{ flexBasis: 'calc(100% - 100px)', width: '100%', fontWeight: 500 }}><center>Invite Link</center></div>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}></div>
            </div>
            <div className="inviterLinkSection">
                <center>
                    <div className="inviteLinkSectionTopText">*The invitee will get ₹10 reward</div>
                    <div className="inviteLinkSectionSillyText">My Invite Link</div>
                    <input type="text" value={window.location.origin + '/register?ref=' + id} readOnly /> <br />
                    <button className="inviteLinkCopyBtn" onClick={copyLink}>Copy Link and Share</button>
                </center>
            </div>
            <div className="sharePictureSection">
                <div className="sharePictureSillyText">Share Picture</div>
                <div className="sharePictureContainer">
                    <div className="sharePictureCol">
                        <img src={img1} width="100%" alt="" />
                        <button className="savePictureBtn" onClick={() => saveImg(img1)}>Save Picture</button>
                    </div>
                    <div className="sharePictureCol">
                        <img src={img2} width="100%" alt="" />
                        <button className="savePictureBtn" onClick={() => saveImg(img2)}>Save Picture</button>
                    </div>
                    <div className="sharePictureCol">
                        <img src={img3} width="100%" alt="" />
                        <button className="savePictureBtn" onClick={() => saveImg(img3)}>Save Picture</button>
                    </div>
                </div>
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default InviteLink