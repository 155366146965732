import React, { useState, useEffect } from 'react'
import { GiCheckMark } from 'react-icons/gi'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { MdDelete } from 'react-icons/md'
import './Withdrawal.css'
import axios from 'axios'

const PaymentMethods = () => {
    const [available, setAvailable] = useState(false)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/allAccount`, { id: user })
            .then(response => {
                console.log(response.data)
                if (response.data.active) {
                    setAvailable(true)
                    setData(response.data?.data)
                }
            }).catch(error => {
                console.log(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBal()
    }, [])
    return (
        <div style={{ minHeight: '100vh', background: '#fff' }}>
            <div className="checkIn__nav" style={{ borderBottom: '1px solid #f0f0f0', height: 40 }}>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}>
                    <div className="top__nav__back__btn" onClick={() => window.location.replace('/withdraw')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="checkIn__nav__col" style={{ flexBasis: 'calc(100% - 100px)', width: '100%' }}><center>Payment Methods</center></div>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}></div>
            </div>

            {available ? (
                <div className="passbook__details">
                    {data?.map((item, index) => {
                        return (
                            item.isBank ? (
                                <div className="passbook__detail__box" style={{ marginTop: 15 }} key={index} onClick={() => alert("hello")}>
                                    <div className="to__bank">Bank</div>
                                    {item.isActive ? (
                                        <div className="passbook__active__container">
                                            <div className="passbook__active__left">
                                                <MdDelete />
                                            </div>
                                            <div className="passbook__active">
                                                <GiCheckMark />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="passbook__active__container">
                                            <div className="passbook__active__left">
                                                <MdDelete />
                                            </div>
                                        </div>
                                    )}
                                    <div className="passbook__detail">
                                        <div className="passbook__detail__col">
                                            <div className="passbook__detail__col__left">Name</div>
                                            <div className="passbook__detail__col__right">{item.name}</div>
                                        </div>
                                        <div className="passbook__detail__col">
                                            <div className="passbook__detail__col__left">IFSC</div>
                                            <div className="passbook__detail__col__right">{item.ifsc?.toUpperCase()}</div>
                                        </div>
                                        <div className="passbook__detail__col">
                                            <div className="passbook__detail__col__left">Account Number</div>
                                            <div className="passbook__detail__col__right">{item.account}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="passbook__detail__box" style={{ marginTop: 15 }} onClick={() => alert("hello")} key={index}>
                                        <div className="to__bank">UPI</div>
                                        {item.isActive ? (
                                            <div className="passbook__active__container">
                                                <div className="passbook__active__left" onClick={() => alert("hi")}>
                                                    <MdDelete />
                                                </div>
                                                <div className="passbook__active">
                                                    <GiCheckMark />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="passbook__active__container">
                                                <div className="passbook__active__left">
                                                    <MdDelete />
                                                </div>
                                            </div>
                                        )}
                                        <div className="passbook__detail">
                                            <center>
                                                <div className="passbook__detail__type__10" style={{ marginTop: 0 }}>{item.name}</div>
                                                <div className="passbook__detail__type__10">{item.upi}</div>
                                            </center>
                                        </div>
                                    </div>
                            )
                        )
                    })}
                </div>
            ) : (
                <></>
            )}

            <div className="payment__method__btns">
                <center>
                    <button onClick={() => window.location.replace('/addCard?type=upi')}>+ New UPI</button> <br />
                    <button onClick={() => window.location.replace('/addCard?type=bank')}>+ New Bank Account</button>
                </center>
            </div>
        </div>
    )
}

export default PaymentMethods