import React, { useState, useEffect } from 'react'
import './Invite.css'
import { IoIosArrowRoundBack } from 'react-icons/io'
import axios from 'axios'
import Loading from '../../Components/Loading'
import { ToastContainer, toast } from 'react-toast'

const Team = () => {
    const [loader, setLoader] = useState(true)
    const [lv1, setLv1] = useState([])
    const [lv2, setLv2] = useState([])
    const [lv3, setLv3] = useState([])
    const [active, setActive] = useState(1)
    const [referral, setReferral] = useState({
        lv1: 0,
        lv2: 0,
        lv3: 0
    })

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/team`, { id: user })
            .then(response => {
                setReferral({
                    ...referral,
                    lv1: response.data.t1,
                    lv2: response.data.t2,
                    lv3: response.data.t3,
                })

                setLv1(response.data.d1)
                setLv2(response.data.d2)
                setLv3(response.data.d3)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBal()
    }, [])

    return (
        <div>
            {loader ? <Loading /> : <></>}

            <div className='app__top__nav' style={{ background: '#fff' }}>
                <div className="top__nav__cols" style={{ flexBasis: 50, width: '100%' }}>
                    <div className="top__nav__back__btn" onClick={() => window.history.go(-1)}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="top__nav__cols" style={{ flexBasis: "calc(100% - 100px)", width: '100%' }}>
                    <center>
                        <div className="top__nav__title" style={{ fontSize: 16, fontWeight: 500 }}>My Team</div>
                    </center>
                </div>
                <div className="top__nav__cols" style={{ flexBasis: 50, width: '100%' }}></div>
            </div>

            <div className="totalInvite__section">
                <div className="totalInvite__text">The total number of invites is: &nbsp; <span style={{ color: 'red', fontWeight: 600}}>{parseFloat(referral.lv1) + parseFloat(referral.lv2) + parseFloat(referral.lv3) }</span></div>
                <table>
                    <tr className="totalInvite__box__head">
                        <th style={{ borderLeft: "1px solid #907fff"}}></th>
                        <th>Level 1</th>
                        <th>Level 2</th>
                        <th>Level 3 </th>
                    </tr>
                    <tr className="totalInvite__box__body">
                        <td className="totalInvite__box__body__tx">Number</td>
                        <td>{referral.lv1}</td>
                        <td>{referral.lv2}</td>
                        <td>{referral.lv3}</td>
                    </tr>
                </table>
            </div>

            <div className="teamView__section" style={{ padding: "20px 0", background: '#fff' }}>
                <div className="levelSelect__section">
                    <div className="parity__records__section__nav" style={{ fontSize: 15, fontWeight: 500 }}>
                        <div className={active === 1 ? "parity__records__section__nav__col active__parity__record__nav" : "parity__records__section__nav__col"} onClick={() => setActive(1)}>Level 1</div>
                        <div className={active === 2 ? "parity__records__section__nav__col active__parity__record__nav" : "parity__records__section__nav__col"} onClick={() => setActive(2)}>Level 2</div>
                        <div className={active === 3 ? "parity__records__section__nav__col active__parity__record__nav" : "parity__records__section__nav__col"} onClick={() => setActive(3)}>Level 3</div>
                    </div>
                </div>

                <div className="teamView__table">
                    <div className="teamView__table__head">
                        <div className="teamView__registration__field">Registration</div>
                        <div className="teamView__id__field">User</div>
                        <div className="teamView__deposit__field">Deposits</div>
                        <div className="teamView__bonus__field">Bonus</div>
                    </div>
                    {active === 1 ? (
                        lv1.length === 0 ? (
                            <div className="teamView__found">No Records Found!</div>
                        ) : (
                            lv1.map((item, index) => {
                                return (
                                    <div className="teamView__table__body" key={index}>
                                        <div className="teamView__registration__field">{item.date}</div>
                                        <div className="teamView__id__field">{item.user}</div>
                                        <div className="teamView__deposit__field">₹{item.totalDeposit}</div>
                                        <div className="teamView__bonus__field">₹{item.bonus}</div>
                                    </div>
                                )
                            })
                        )
                    ) : (
                        active === 2 ? (
                            lv2.length === 0 ? (
                                <div className="teamView__found">No Records Found!</div>
                            ) : (
                                    lv2.map((item, index) => {
                                        return (
                                            <div className="teamView__table__body" key={index}>
                                                <div className="teamView__registration__field">{item.date}</div>
                                                <div className="teamView__id__field">{item.user}</div>
                                                <div className="teamView__deposit__field">₹{item.totalDeposit}</div>
                                                <div className="teamView__bonus__field">₹{item.bonus}</div>
                                            </div>
                                        )
                                    })
                            )
                        ) : (
                            active === 3 ? (
                                lv3.length === 0 ? (
                                    <div className="teamView__found">No Records Found!</div>
                                ):(
                                    lv3.map((item, index) => {
                                        return (
                                            <div className="teamView__table__body" key={index}>
                                                <div className="teamView__registration__field">{item.date}</div>
                                                <div className="teamView__id__field">{item.user}</div>
                                                <div className="teamView__deposit__field">₹{item.totalDeposit}</div>
                                                <div className="teamView__bonus__field">₹{item.bonus}</div>
                                            </div>
                                        )
                                    })
                                )
                            ) : (
                                <></>
                            )
                        )
                    )}
                </div>
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Team