import React, { useState, useEffect } from 'react'
import './Dice.css'
import axios from 'axios'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { IoMdClose } from 'react-icons/io'
import { Slider } from '@mui/material'
import { io } from 'socket.io-client';
import { ToastContainer, toast } from 'react-toast'
import Loading from '../../../Components/Loading'

//, { transports: ["websocket", "polling", "flashsocket"], forceNew: true }
const socket = io.connect(process.env.REACT_APP_BACKEND_URL, { transports: ["websocket", "polling", "flashsocket"], forceNew: true });

const Dice = () => {
    const [val, setVal] = useState(1)
    const [period, setPeriod] = useState("")
    const [data, setData] = useState()
    const [orderType, setOrderType] = useState(false)
    const [order, setOrder] = useState([])
    const [myOrder, setMyOrder] = useState([])
    const [betAmount, setBetAmount] = useState(10)
    const [balance, setBalance] = useState(0)
    const [loader, setLoader] = useState(false)
    const [popup, setPopup] = useState({
        popup: false,
        type: true,
        select: ''
    })
    const [count, setCount] = useState({
        n1: "0",
        n2: "0",
        n3: "0",
        n4: "0"
    })
    const [resultPopup, setResultPopup] = useState({
        popup: false,
        win: '',
        period: '',
        price: '19975.01',
        select: '',
        point: '',
        amount: ''
    });

    socket.on("counterDice", ({ counter }) => {
        const countdown = parseFloat(counter)
        if (countdown < 60) {
            let no = countdown.toString();
            let n1 = countdown < 10 ? "0" : no.charAt(0)
            let n2 = countdown < 10 ? no.charAt(0) : no.charAt(1)

            setCount({
                ...count,
                n1: "0",
                n2: "0",
                n3: n1,
                n4: n2
            })

            if (countdown < 4) { setPopup({ ...popup, popup: false }) }
        } else {
            let min = parseInt(countdown / 60)
            let sec = countdown - (parseInt(min) * 60)
            let ss = sec.toString()
            let s1 = sec < 10 ? "0" : ss.charAt(0)
            let s2 = sec < 10 ? ss.charAt(0) : ss.charAt(1)
            setCount({
                ...count,
                n1: "0",
                n2: min,
                n3: s1,
                n4: s2
            })
        }
    });

    async function fetchRecords() {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/game/dice`).then(response => {
            setPeriod(response.data?.current)
            setData(response.data?.data.reverse())
        }).catch((error) => {
            toast.error(error.response.data?.error)
        })
    }

    useEffect(() => {
        fetchRecords()
    }, [period])

    socket.on("periodDice", (data) => {
        setPeriod(data.period)
    });

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/balance`, { id: user })
            .then(response => {
                setBalance(parseFloat(response.data?.withdraw) + parseFloat(response.data?.deposit))
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBal()
    }, [popup.popup, period])

    async function confirmBet() {
        let user = localStorage.getItem("user");
        setPopup({ ...popup, popup: false })
        setLoader(true)

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/placeDiceBet`, { amount: betAmount, period: period, user: user, select: popup.select }).then((response) => {
            setLoader(false)
            toast.success('Bet placed successfully')
            socket.emit('betDice', { user: response.data.user, period: response.data.period, select: response.data.select, amount: response.data.amount })
        }).catch(error => {
            setLoader(false)
            toast.error(error.response.data?.error)
        })
    }

    async function getMyOrder() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/myOrder/dice`, { id: user })
            .then(response => {
                setMyOrder(response.data.data)
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        getMyOrder()
    }, [orderType, period])

    useEffect(() => {
        if (!socket) return;

        socket.on('betForwardDice', ({ period, user, select, amount }) => {
            console.log(select)
            setOrder((state) => [
                ...state,
                {
                    period: period,
                    user: user,
                    select: select,
                    amount: amount,
                },
            ]);
        });

        return () => socket.off('betForward');
    }, [socket]);

    useEffect(() => {
        if (!socket) return;

        socket.on('result2', ({ token, period, price, select, point, result }) => {
            if (token === localStorage.getItem("user")) {
                if (parseFloat(select) > parseFloat(result)) {
                    setResultPopup({
                        ...popup,
                        popup: true,
                        win: true,
                        period: period,
                        price,
                        select,
                        amount: ((95 / select) * point).toFixed(2),
                        point,
                        result
                    })
                } else {
                    setResultPopup({
                        ...popup,
                        popup: true,
                        win: false,
                        period: period,
                        price,
                        select,
                        amount: point,
                        point,
                        result
                    })
                }
            }
        });

        return () => socket.off('result');
    }, [socket]);

    return (
        <div style={{ minHeight: '100vh', background: '#fff' }}>
            {loader ? <Loading /> : <></>}
            {resultPopup.popup ? (
                <div className="parityResultPopupScreen">
                    <div className="parityResultPopup">
                        <div className="parityResultPopupHeaderImg">
                            <img className={resultPopup.win ? "parityResultPopupWinHeaderImage" : "parityResultPopupLoseHeaderImage"} src={resultPopup.win ? "https://res.cloudinary.com/fiewin/image/upload/images/winImg.png" : "https://res.cloudinary.com/fiewin/image/upload/images/loseImg.png"} alt="" />
                        </div>
                        <div className="parityResultPopupNumberBox">
                            <div className="parityResultPopupNumber">{resultPopup.result}</div>
                        </div>
                        <div className="parityResultPopupDetailBox1">
                            <div className="parityResultPopupDetailBox1Col">
                                <div className="parityResultPopupDetailBox1ColLeft">Period</div>
                                <div className="parityResultPopupDetailBox1ColLeft">{resultPopup.period}</div>
                            </div>
                            <div className="parityResultPopupDetailBox1Col">
                                <div className="parityResultPopupDetailBox1ColLeft">Price</div>
                                <div className="parityResultPopupDetailBox1ColLeft">${resultPopup.price}</div>
                            </div>
                        </div>
                        <div className="parityResultPopupDetailBox2Container">
                            <div className="parityResultPopupDetailBox2">
                                <div className="parityResultPopupDetailBox2Col">
                                    <div className="parityResultPopupDetailBox2ColLeft">Select</div>
                                    <div className="parityResultPopupDetailBox2ColLeft">{resultPopup.select}</div>
                                </div>
                                <div className="parityResultPopupDetailBox2Col">
                                    <div className="parityResultPopupDetailBox2ColLeft">Point</div>
                                    <div className="parityResultPopupDetailBox2ColLeft">{resultPopup.point}</div>
                                </div>
                                <div className="parityResultPopupDetailBox2Col">
                                    <div className="parityResultPopupDetailBox2ColLeft">Amount</div>
                                    <div className="parityResultPopupDetailBox2ColLeft">{resultPopup.win ? '+' : '-'}{resultPopup.amount}</div>
                                </div>
                            </div>
                            <button className="parityResultPopupClose" onClick={() => setResultPopup({ ...resultPopup, popup: false })}>OK</button>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {popup.popup ? (
                <div className="fast__parity__popup">
                    <div className="fast__parity__popup__inner">
                        <div className="fast__parity__popup__nav">
                            <div style={{ flexBasis: 50, width: '100%' }}></div>
                            <div className="fast__parity__popup__nav__col">Dice-<span style={popup.type ? { color: popup.select } : {}}>{popup.select}</span></div>
                            <div style={{ flexBasis: 50, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'right', height: 60, fontSize: 20 }} onClick={() => setPopup({ ...popup, popup: false })}>
                                <IoMdClose />
                            </div>
                        </div>

                        <div className="fast__parity__popup__balance__box">
                            <div className="fast__parity__popup__balance__box__left">
                                <div className="fast__parity__popup__title">Balance</div>
                                <div className="fast__parity__popup__balance__box__left__bottom">₹ {balance}</div>
                            </div>
                            <button className="fast__parity__popup__balance__box__right" onClick={() => window.location.replace('/recharge')}>Recharge</button>
                        </div>

                        <div className="fast__parity__popup__contract__section">
                            <div className="fast__parity__popup__title">Contract Amount</div>
                            <div className="fast__parity__popup__contract__amount__box__container">
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 10 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(10)}>10</div>
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 100 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(100)}>100</div>
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 1000 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(1000)}>1000</div>
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 10000 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(10000)}>10000</div>
                            </div>

                            <div className="fast__parity__popup__contract__amount__input">
                                <button className="fast__parity__popup__contract__amount__input__col" onClick={() => setBetAmount(betAmount - 10)}>-</button>
                                <input type="number" onChange={(e) => setBetAmount(e.target.value)} value={betAmount} />
                                <button className="fast__parity__popup__contract__amount__input__col" onClick={() => setBetAmount(betAmount + 10)}>+</button>
                            </div>

                            {betAmount > 9 ? <button className="parity__bet__btn" onClick={confirmBet}>Confirm</button> : <button className="parity__bet__btn" style={{ background: '#ccc', color: '#555' }}>Confirm</button>}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className="checkIn__nav" style={{ borderBottom: '1px solid #f0f0f0', height: 40 }}>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}>
                    <div className="top__nav__back__btn" onClick={() => window.location.replace('/home')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="checkIn__nav__col" style={{ flexBasis: 'calc(100% - 100px)', width: '100%', fontWeight: 500 }}><center>Dice</center></div>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}></div>
            </div>

            <div className="parity__prediction__screen">
                <div className="parity__prediction__timer">
                    <div className="parity__prediction__timer__col">
                        <div className="parity__prediction__timer__top">Period</div>
                        <div className="parity__prediction__timer__bottom">{period}</div>
                    </div>
                    <div className="parity__prediction__timer__col" style={{ textAlign: 'right' }}>
                        <div className="parity__prediction__timer__top">Count Down</div>
                        <div className="parity__prediction__timer__bottom">{`${count.n1}${count.n2}:${count.n3}${count.n4}`}</div>
                    </div>
                </div>

                <div className="dice__prediction__value">
                    <div className="dice__prediction__value__box">
                        <div className="dice__prediction__value__box__col" style={{ borderRight: '1px solid #999' }}>
                            <center>
                                <div className="dice__prediction__value__box__col__top">Less than</div>
                                <div className="dice__prediction__value__box__col__bottom"> {val} </div>
                            </center>
                        </div>
                        <div className="dice__prediction__value__box__col">
                            <center>
                                <div className="dice__prediction__value__box__col__top">Multiplier</div>
                                <div className="dice__prediction__value__box__col__bottom">{(95 / val).toFixed(2)}</div>
                            </center>
                        </div>
                    </div>

                    <div className="dice__prediction__input__field">
                        <Slider onChange={(e) => setVal(e.target.value)} min={1} max={92} value={val} area-label='Default' valueLabelDisplay='auto' />

                        {parseFloat(count.n3) === 0 && parseFloat(count.n4) < 4 ? <button className="placeDiceBet" style={{ background: '#ccc', color: '#555' }}>Less than {val} </button> : <button className="placeDiceBet" onClick={() => setPopup({ ...popup, popup: true, type: true, select: val })}>Less than {val} </button>}
                    </div>
                </div>
            </div>

            <div className="parity__records__section" style={{ marginTop: 20 }}>
                <div className="parity__records__section__nav__headd"></div>
                <div className="parity__records__section__nav2">
                    <div className="parity__records__section__nav__head">
                        <div className="parity__records__section__nav__head__left">Dice Records</div>
                        <div className="parity__records__section__nav__head__right">More > </div>
                    </div>
                </div>

                <div className="parity__records__section__record">
                    <div className="parity__records__section__record__container">
                        {data?.map((item, index) => {
                            const peri = item.id.toString().slice(-3)

                            return (
                                <div className="parity__records__start" key={index}>
                                    <div className="parity__record__number" style={{ fontSize: 13, color: '#555' }}>{peri}</div>
                                    <div className="parity__records__circle" style={{ background: '#312d47', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, fontSize: 14 }}> {parseFloat(item.result) === 100 ? "?" : item.result} </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="parity__records__section">
                <div className="parity__records__section__nav__headd"></div>
                <div className="parity__records__section__nav">
                    <div className={orderType ? "parity__records__section__nav__col" : "parity__records__section__nav__col active__parity__record__nav"} onClick={() => setOrderType(false)}>Everyone's Order</div>
                    <div className={!orderType ? "parity__records__section__nav__col" : "parity__records__section__nav__col active__parity__record__nav"} onClick={() => setOrderType(true)}>My Order</div>
                </div>
                {!orderType ? (
                    <div className="parity__user__order__section">
                        <div className="parity__user__order__box__head">
                            <div className="parity__user__order__box__period">Period</div>
                            <div className="parity__user__order__box__user">User</div>
                            <div className="parity__user__order__box__select">Select</div>
                            <div className="parity__user__order__box__amount">Amount</div>
                        </div>
                        {order.map((item, index) => {
                            return (
                                <div className="parity__user__order__box" key={index}>
                                    <div className="parity__user__order__box__period">{item.period}</div>
                                    <div className="parity__user__order__box__user">{item.user}</div>
                                    <div className="parity__user__order__box__select">
                                        <div style={{ height: 30, width: 30 }}>
                                            <div className="parity__records__circle" style={{ background: '#312d47', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, fontSize: 14 }}> {item.select} </div>
                                        </div>
                                    </div>
                                    <div className="parity__user__order__box__amount">₹{item.amount}</div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className="parity__user__order__section">
                        <div className="parity__user__order__box__head">
                            <div className="parity__user__order__box__period">Period</div>
                            <div className="parity__user__order__box__head__select">Select</div>
                            <div className="parity__user__order__box__head__select">Result</div>
                            <div className="parity__user__order__box__head__amount">Amount</div>
                        </div>

                        {myOrder.map((item, index) => {
                            return (
                                <div className="parity__user__order__box" key={index}>
                                    <div className="parity__user__order__box__period">{item.period}</div>
                                    <div className="parity__user__order__box__head__select">
                                        <div style={{ height: 30, width: 30 }}>
                                            <div className="parity__records__circle" style={{ background: '#312d47', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, fontSize: 14 }}> {parseFloat(item.select) === 100 ? "" : item.select} </div>
                                        </div>
                                    </div>
                                    <div className="parity__user__order__box__head__select">
                                        <div style={{ height: 30, width: 30 }}>
                                            <div className="parity__records__circle" style={{ background: '#312d47', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, fontSize: 14 }}> {item.result} </div>
                                        </div>
                                    </div>
                                    <div className="parity__user__order__box__head__amount">₹{item.amount}</div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default Dice