import React, { useState, useEffect } from 'react'
import './Withdrawal.css'
import { IoIosArrowRoundBack } from 'react-icons/io'
import axios from 'axios'

const AddCard = () => {
    const query = new URLSearchParams(window.location.search);
    const type = query.get('type')

    const [data, setData] = useState({
        name: '',
        isBank: type && type.toLowerCase() === 'upi' ? false : true,
        accountNumber: '',
        ifsc: '',
        upi: '',
        cupi: ''
    });

    async function addCard() {
        let user = localStorage.getItem("user");
        let { name, isBank, accountNumber, ifsc, upi, cupi } = data;

        if (isBank) {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/addCard`, { id: user, name, bank: isBank, accountNumber, ifsc, upi })
                .then(response => {
                    window.location.replace('/paymentMethods')
                }).catch(error => {
                    console.log(error)
                })
        } else {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/addCard`, { id: user, name, bank: isBank, upi })
                .then(response => {
                    window.location.replace('/paymentMethods')
                }).catch(error => {
                    console.log(error)
                })
        }
    }

    return (
        <div style={{ minHeight: '100vh', background: '#fff' }}>
            <div className="checkIn__nav" style={{ borderBottom: '1px solid #f0f0f0', height: 40 }}>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}>
                    <div className="top__nav__back__btn" onClick={() => window.location.replace('/paymentMethods')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="checkIn__nav__col" style={{ flexBasis: 'calc(100% - 100px)', width: '100%' }}><center>{type && type.toLowerCase() === 'upi' ? "Add UPI" : "Add Bank Account"}</center></div>
                <div className="checkIn__nav__col" style={{ flexBasis: 50, width: '100%' }}></div>
            </div>

            <div className="addCard__fields">
                <input type="text" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} placeholder='Please input name' /> <br />
                {data.isBank ? <input type="text" onChange={(e) => setData({ ...data, ifsc: e.target.value })} value={data.ifsc} placeholder='Please input IFSC Code' /> : <input type="text" value={data.upi} onChange={(e) => setData({ ...data, upi: e.target.value })} placeholder='Please input UPI address' />} <br />
                {data.isBank ? <input type="number" value={data.accountNumber} onChange={(e) => setData({ ...data, accountNumber: e.target.value })} placeholder='Please input Account Number' /> : <input type="text" value={data.cupi} onChange={(e) => setData({ ...data, cupi: e.target.value })} placeholder='Please confirm UPI address' />}
            </div>

            <div className="payment__method__btns">
                <center>
                    <button onClick={addCard}>{data.isBank ? "Add Bank" : "Add UPI"}</button> <br />
                    <button onClick={() => window.location.replace('/paymentMethods')} style={{ background: '#fff', border: '1.5px solid #555', color: '#000' }}>Cancel</button>
                </center>
            </div>
        </div>
    )
}

export default AddCard