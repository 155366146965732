import React, { useEffect, useState } from 'react'
import './Parity.css'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { io } from 'socket.io-client';
import axios from 'axios'
import { IoMdClose } from 'react-icons/io'
import { ToastContainer, toast } from 'react-toast'
import Loading from '../../../Components/Loading'

//, { transports: ["websocket", "polling", "flashsocket"], forceNew: true }
const socket = io.connect(process.env.REACT_APP_BACKEND_URL, { transports: ["websocket", "polling", "flashsocket"], forceNew: true });

const FastParity = () => {
    const [period, setPeriod] = useState("");
    const [data, setData] = useState()
    const [betAmount, setBetAmount] = useState(10)
    const [balance, setBalance] = useState(0)
    const [resultPopup, setResultPopup] = useState({
        popup: false,
        win: '',
        period: '',
        price: '19975.01',
        select: '',
        point: '',
        amount: ''
    });

    const [myOrder, setMyOrder] = useState([])
    const [count, setCount] = useState({
        n1: "0",
        n2: "0",
        n3: "0",
        n4: "0"
    })
    const [popup, setPopup] = useState({
        popup: false,
        type: true,
        select: ''
    })
    const [order, setOrder] = useState([])
    const [fet, setFet] = useState(0)
    const [orderType, setOrderType] = useState(false)
    const [loader, setLoader] = useState(false)

    socket.on("connect", () => {
        console.log('connected')
    });

    socket.on("disconnect", () => {
        console.log("disconnect")
    })

    async function fetchRecords() {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/game/fastParity`).then(response => {
            setPeriod(response.data?.current)
            setData(response.data?.data.reverse())
        }).catch((error) => {
            toast.error(error.response.data?.error)
        })
    }

    useEffect(() => {
        fetchRecords()
    }, [period])

    socket.on("counter", ({ counter }) => {
        const countdown = parseFloat(counter)
        if (countdown < 60) {
            let no = countdown.toString();
            let n1 = countdown < 10 ? "0" : no.charAt(0)
            let n2 = countdown < 10 ? no.charAt(0) : no.charAt(1)

            setCount({
                ...count,
                n1: "0",
                n2: "0",
                n3: n1,
                n4: n2
            })

            if (countdown < 4) { setPopup({ ...popup, popup: false }) }
        } else {
            let min = parseInt(countdown / 60)
            let sec = countdown - (parseInt(min) * 60)
            let ss = sec.toString()
            let s1 = sec < 10 ? "0" : ss.charAt(0)
            let s2 = sec < 10 ? ss.charAt(0) : ss.charAt(1)
            setCount({
                ...count,
                n1: "0",
                n2: min,
                n3: s1,
                n4: s2
            })
        }
    });

    socket.on("period", (data) => {
        setPeriod(data.period)
    });

    socket.on("disconnect", () => {
        //console.log(socket.id);
    });

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/balance`, { id: user })
            .then(response => {
                setBalance(parseFloat(response.data?.withdraw) + parseFloat(response.data?.deposit))
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBal()
    }, [popup.popup])

    async function confirmBet() {
        let user = localStorage.getItem("user");
        setPopup({ ...popup, popup: false })
        setLoader(true)

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/placeFastParityBet`, { amount: betAmount, period: period, user: user, select: popup.select, type: popup.type ? 'Color' : 'Number' }).then((response) => {
            setLoader(false)
            toast.success('Bet placed successfully')
            socket.emit('bet', { user: response.data.user, period: response.data.period, select: response.data.select, amount: response.data.amount, type: response.data.type })
        }).catch(error => {
            setLoader(false)
            toast.error(error.response.data?.error)
        })
    }

    async function getMyOrder() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/myOrder`, { id: user })
            .then(response => {
                setMyOrder(response.data.data)
            }).catch(error => {
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        getMyOrder()
    }, [orderType, period, fet])

    useEffect(() => {
        if (!socket) return;

        socket.on('betForward', ({ period, user, select, amount, type }) => {
            console.log(select)
            setOrder((state) => [
                ...state,
                {
                    period: period,
                    user: user,
                    select: select,
                    amount: amount,
                    type: type
                },
            ]);
        });

        return () => socket.off('betForward');
    }, [socket]);

    useEffect(() => {
        if (!socket) return;

        socket.on('result', ({ token, period, price, select, point, type, result }) => {
            if (token === localStorage.getItem("user")) {
                if(type) {
                    let G = result === 1 || result === 3 || result === 5 || result === 7 || result === 9
                    let R = result === 0 || result === 2 || result === 4 || result === 6 || result === 8
                    let V = result === 5 || result === 0

                    if(R && select === 'R' || G && select === 'G') {
                        setResultPopup({
                            ...popup,
                            popup: true,
                            win: true,
                            period: period,
                            price,
                            select,
                            amount: parseFloat(point) * 2,
                            point,
                            result
                        })
                    } else {
                        if(V && select === 'V') {
                            setResultPopup({
                                ...popup,
                                popup: true,
                                win: true,
                                period: period,
                                price,
                                select,
                                amount: parseFloat(point) * 4.5,
                                point,
                                result
                            })
                        } else {
                            setResultPopup({
                                ...popup,
                                popup: true,
                                win: false,
                                period: period,
                                price,
                                select,
                                amount: point,
                                point,
                                result
                            })
                        }
                    }
                } else {
                    if(select === result) {
                        setResultPopup({
                            ...popup,
                            popup: true,
                            win: true,
                            period: period,
                            price,
                            select,
                            amount: parseFloat(point) * 9,
                            point,
                            result
                        })
                    } else {
                        setResultPopup({
                            ...popup,
                            popup: true,
                            win: false,
                            period: period,
                            price,
                            select,
                            amount: point,
                            point,
                            result
                        })
                    }
                }
            }
        });

        return () => socket.off('result');
    }, [socket]);

    return (
        <div className='home__page'>
            {loader ? <Loading /> : <></>}
            
            {resultPopup.popup ? (
                <div className="parityResultPopupScreen">
                    <div className="parityResultPopup">
                        <div className="parityResultPopupHeaderImg">
                            <img className={resultPopup.win ? "parityResultPopupWinHeaderImage" : "parityResultPopupLoseHeaderImage"} src={resultPopup.win ? "https://res.cloudinary.com/fiewin/image/upload/images/winImg.png" : "https://res.cloudinary.com/fiewin/image/upload/images/loseImg.png"} alt="" />
                        </div>
                        <div className="parityResultPopupNumberBox">
                            <div className="parityResultPopupNumber">{resultPopup.result}</div>
                        </div>
                        <div className="parityResultPopupDetailBox1">
                            <div className="parityResultPopupDetailBox1Col">
                                <div className="parityResultPopupDetailBox1ColLeft">Period</div>
                                <div className="parityResultPopupDetailBox1ColLeft">{resultPopup.period}</div>
                            </div>
                            <div className="parityResultPopupDetailBox1Col">
                                <div className="parityResultPopupDetailBox1ColLeft">Price</div>
                                <div className="parityResultPopupDetailBox1ColLeft">${resultPopup.price}</div>
                            </div>
                        </div>
                        <div className="parityResultPopupDetailBox2Container">
                            <div className="parityResultPopupDetailBox2">
                                <div className="parityResultPopupDetailBox2Col">
                                    <div className="parityResultPopupDetailBox2ColLeft">Select</div>
                                    <div className="parityResultPopupDetailBox2ColLeft">{resultPopup.select}</div>
                                </div>
                                <div className="parityResultPopupDetailBox2Col">
                                    <div className="parityResultPopupDetailBox2ColLeft">Point</div>
                                    <div className="parityResultPopupDetailBox2ColLeft">{resultPopup.point}</div>
                                </div>
                                <div className="parityResultPopupDetailBox2Col">
                                    <div className="parityResultPopupDetailBox2ColLeft">Amount</div>
                                    <div className="parityResultPopupDetailBox2ColLeft">{resultPopup.win ? '+' : '-'}{resultPopup.amount}</div>
                                </div>
                            </div>
                            <button className="parityResultPopupClose" onClick={() => setResultPopup({...resultPopup, popup: false})}>OK</button>
                        </div>
                    </div>
                </div>
            ):(
                <></>
            )}

            {popup.popup ? (
                <div className="fast__parity__popup">
                    <div className="fast__parity__popup__inner">
                        <div className="fast__parity__popup__nav">
                            <div style={{ flexBasis: 50, width: '100%' }}></div>
                            <div className="fast__parity__popup__nav__col">Parity-<span style={popup.type ? { color: popup.select } : {}}>{popup.select}</span></div>
                            <div style={{ flexBasis: 50, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'right', height: 60, fontSize: 20 }} onClick={() => setPopup({ ...popup, popup: false })}>
                                <IoMdClose />
                            </div>
                        </div>

                        <div className="fast__parity__popup__balance__box">
                            <div className="fast__parity__popup__balance__box__left">
                                <div className="fast__parity__popup__title">Balance</div>
                                <div className="fast__parity__popup__balance__box__left__bottom">₹ {balance}</div>
                            </div>
                            <button className="fast__parity__popup__balance__box__right" onClick={() => window.location.replace('/recharge')}>Recharge</button>
                        </div>

                        <div className="fast__parity__popup__contract__section">
                            <div className="fast__parity__popup__title">Contract Amount</div>
                            <div className="fast__parity__popup__contract__amount__box__container">
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 10 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(10)}>10</div>
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 100 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(100)}>100</div>
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 1000 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(1000)}>1000</div>
                                <div className="fast__parity__popup__contract__amount__box" style={betAmount === 10000 ? { background: '#000', color: '#fff' } : {}} onClick={() => setBetAmount(10000)}>10000</div>
                            </div>

                            <div className="fast__parity__popup__contract__amount__input">
                                <button className="fast__parity__popup__contract__amount__input__col" onClick={() => setBetAmount(betAmount - 10)}>-</button>
                                <input type="number" onChange={(e) => setBetAmount(e.target.value)} value={betAmount} />
                                <button className="fast__parity__popup__contract__amount__input__col" onClick={() => setBetAmount(betAmount + 10)}>+</button>
                            </div>

                            {betAmount > 9 ? <button className="parity__bet__btn" onClick={confirmBet}>Confirm</button> : <button className="parity__bet__btn" style={{ background: '#ccc', color: '#555' }}>Confirm</button>}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className='app__top__nav parity' style={{ borderBottom: '1px solid #ddd', padding: '0 3%' }}>
                <div className="parity__nav__cols">
                    <div className="top__nav__back__btn" onClick={() => window.location.replace('/home')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="parity__nav__cols2">
                    <center>
                        <div className="parity__nav__col">Fast Parity</div>
                    </center>
                </div>
                <div className="parity__nav__cols"></div>
            </div>

            <div className="parity__prediction__screen">
                <div className="parity__prediction__timer">
                    <div className="parity__prediction__timer__col">
                        <div className="parity__prediction__timer__top">Period</div>
                        <div className="parity__prediction__timer__bottom">{period}</div>
                    </div>
                    <div className="parity__prediction__timer__col" style={{ textAlign: 'right' }}>
                        <div className="parity__prediction__timer__top">Count Down</div>
                        <div className="parity__prediction__timer__bottom">{`${count.n1}${count.n2}:${count.n3}${count.n4}`}</div>
                    </div>
                </div>

                <div className="parity__prediction__color__selection__screen">
                    {parseFloat(count.n3) === 0 && parseFloat(count.n4) < 4 ? (
                        <div className="parity__prediction__color__cols">
                            <div className="parity__prediction__color__col__container">
                                <div className="parity__prediction__color__col" style={{ background: "#aaa", color: '#555' }}>Red</div>
                                <span className="parity__prediction__color__col__commission" style={{ fontSize: 14, color: '#555' }}><center>1:2</center></span>
                            </div>
                            <div className="parity__prediction__color__col__container">
                                <div className="parity__prediction__color__col" style={{ background: "#aaa", color: '#555' }}>Violet</div>
                                <span className="parity__prediction__color__col__commission" style={{ fontSize: 14, color: '#555' }}><center>1:5</center></span>
                            </div>
                            <div className="parity__prediction__color__col__container">
                                <div className="parity__prediction__color__col" style={{ background: "#aaa", color: '#555' }}>Green</div>
                                <span className="parity__prediction__color__col__commission" style={{ fontSize: 14, color: '#555' }}><center>1:2</center></span>
                            </div>
                        </div>
                    ) : (
                        <div className="parity__prediction__color__cols">
                            <div className="parity__prediction__color__col__container">
                                <div className="parity__prediction__color__col" style={{ background: "rgb(250, 60, 9)" }} onClick={() => setPopup({ ...popup, popup: true, type: true, select: 'Red' })}>Red</div>
                                <span className="parity__prediction__color__col__commission" style={{ fontSize: 14, color: '#555' }}><center>1:2</center></span>
                            </div>
                            <div className="parity__prediction__color__col__container">
                                <div className="parity__prediction__color__col" style={{ background: "rgb(102, 85, 211)" }} onClick={() => setPopup({ ...popup, popup: true, type: true, select: 'Violet' })}>Violet</div>
                                <span className="parity__prediction__color__col__commission" style={{ fontSize: 14, color: '#555' }}><center>1:5</center></span>
                            </div>
                            <div className="parity__prediction__color__col__container">
                                <div className="parity__prediction__color__col" style={{ background: "rgb(0, 194, 130)" }} onClick={() => setPopup({ ...popup, popup: true, type: true, select: 'Green' })}>Green</div>
                                <span className="parity__prediction__color__col__commission" style={{ fontSize: 14, color: '#555' }}><center>1:2</center></span>
                            </div>
                        </div>
                    )}
                    <div className="parity__prediction__number__cols__container">
                        {parseFloat(count.n3) === 0 && parseFloat(count.n4) < 4 ? (
                            <div className="parity__prediction__number__cols">
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>1</div>
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>2</div>
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>3</div>
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>4</div>
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>5</div>
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>6</div>
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>7</div>
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>8</div>
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>9</div>
                                <div className="parity__prediction__number__col" style={{ background: '#aaa', border: 0, color: '#555' }}>0</div>
                            </div>
                        ) : (
                            <div className="parity__prediction__number__cols">
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 1 })}>1</div>
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 2 })}>2</div>
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 3 })}>3</div>
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 4 })}>4</div>
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 5 })}>5</div>
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 6 })}>6</div>
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 7 })}>7</div>
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 8 })}>8</div>
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 9 })}>9</div>
                                <div className="parity__prediction__number__col" onClick={() => setPopup({ ...popup, popup: true, type: false, select: 0 })}>0</div>
                            </div>
                        )}
                        <span style={{ fontSize: 14, color: '#555' }}><center>1:9</center></span>
                    </div>
                </div>
            </div>

            <div className="parity__records__section">
                <div className="parity__records__section__nav__headd"></div>
                <div className="parity__records__section__nav2">
                    <div className="parity__records__section__nav__head">
                        <div className="parity__records__section__nav__head__left">Parity Records</div>
                        <div className="parity__records__section__nav__head__right">More > </div>
                    </div>
                </div>
                <div className="parity__records__section__record">
                    <div className="parity__records__section__record__container">
                        {data?.map((item, index) => {
                            const winner = parseFloat(item.winner)
                            const peri = item.id.toString().slice(-3)

                            return (
                                <div className="parity__records__start" key={index}>
                                    <div className="parity__record__number" style={{ fontSize: 13, color: '#555' }}>{peri}</div>
                                    <div className="parity__records__circle">
                                        <div className="parity__records__circle__no">{winner === 10 ? "?" : winner}</div>
                                        <div className="parity__records__circle__inner">
                                            <div className="parity__records__circle__col" style={winner === 1 || winner === 3 || winner === 7 || winner === 9 || winner === 5 ? { background: "rgb(0, 194, 130)" } : winner === 10 ? { background: "#888" } : { background: "rgb(250, 60, 9)" }}></div>
                                            <div className="parity__records__circle__col" style={winner === 1 || winner === 3 || winner === 7 || winner === 9 ? { background: "rgb(0, 194, 130)" } : winner === 0 || winner === 5 ? { background: "rgb(102, 85, 211)" } : winner === 10 ? { background: "#888" } : { background: "rgb(250, 60, 9)" }}></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="parity__records__section">
                <div className="parity__records__section__nav__headd"></div>
                <div className="parity__records__section__nav">
                    <div className={orderType ? "parity__records__section__nav__col" : "parity__records__section__nav__col active__parity__record__nav"} onClick={() => setOrderType(false)}>Everyone's Order</div>
                    <div className={!orderType ? "parity__records__section__nav__col" : "parity__records__section__nav__col active__parity__record__nav"} onClick={() => setOrderType(true)}>My Order</div>
                </div>
                {!orderType ? (
                    <div className="parity__user__order__section">
                        <div className="parity__user__order__box__head">
                            <div className="parity__user__order__box__period">Period</div>
                            <div className="parity__user__order__box__user">User</div>
                            <div className="parity__user__order__box__select">Select</div>
                            <div className="parity__user__order__box__amount">Amount</div>
                        </div>
                        {order.map((item, index) => {
                            return (
                                <div className="parity__user__order__box" key={index}>
                                    <div className="parity__user__order__box__period">{item.period}</div>
                                    <div className="parity__user__order__box__user">{item.user}</div>
                                    <div className="parity__user__order__box__select">
                                        <div style={{ height: 30, width: 30 }}>
                                            <div className="parity__records__circle">
                                                <div className="parity__records__circle__no">{item.select === 10 ? "?" : item.select}</div>
                                                <div className="parity__records__circle__inner">
                                                    <div className="parity__records__circle__col" style={item.type === 'number' ? item.select === 1 || item.select === 3 || item.select === 7 || item.select === 9 || item.select === 5 ? { background: "rgb(0, 194, 130)" } : item.select === 10 ? { background: "#888" } : { background: "rgb(250, 60, 9)" } : item.select === 'R' ? { background: "rgb(250, 60, 9)" } : item.select === 'G' ? { background: "rgb(0, 194, 130)" } : { background: "rgb(102, 85, 211)" }}></div>
                                                    <div className="parity__records__circle__col" style={item.type === 'number' ? item.select === 1 || item.select === 3 || item.select === 7 || item.select === 9 ? { background: "rgb(0, 194, 130)" } : item.select === 0 || item.select === 5 ? { background: "rgb(102, 85, 211)" } : item.select === 10 ? { background: "#888" } : { background: "rgb(250, 60, 9)" } : item.select === 'R' ? { background: "rgb(250, 60, 9)" } : item.select === 'G' ? { background: "rgb(0, 194, 130)" } : { background: "rgb(102, 85, 211)" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="parity__user__order__box__amount">₹{item.amount}</div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className="parity__user__order__section">
                        <div className="parity__user__order__box__head">
                            <div className="parity__user__order__box__period">Period</div>
                            <div className="parity__user__order__box__head__select">Select</div>
                            <div className="parity__user__order__box__head__select">Result</div>
                            <div className="parity__user__order__box__head__amount">Amount</div>
                        </div>

                        {myOrder.map((item, index) => {
                            return (
                                <div className="parity__user__order__box" key={index}>
                                    <div className="parity__user__order__box__period">{item.period}</div>
                                    <div className="parity__user__order__box__head__select">
                                        <div style={{ height: 30, width: 30 }}>
                                            <div className="parity__records__circle">
                                                <div className="parity__records__circle__no">{item.select === 10 ? "?" : item.select}</div>
                                                <div className="parity__records__circle__inner">
                                                    <div className="parity__records__circle__col" style={item.type === 'number' ? item.select === 1 || item.select === 3 || item.select === 7 || item.select === 9 || item.select === 5 ? { background: "rgb(0, 194, 130)" } : item.select === 10 ? { background: "#888" } : { background: "rgb(250, 60, 9)" } : item.select === 'R' ? { background: "rgb(250, 60, 9)" } : item.select === 'G' ? { background: "rgb(0, 194, 130)" } : { background: "rgb(102, 85, 211)" }}></div>
                                                    <div className="parity__records__circle__col" style={item.type === 'number' ? item.select === 1 || item.select === 3 || item.select === 7 || item.select === 9 ? { background: "rgb(0, 194, 130)" } : item.select === 0 || item.select === 5 ? { background: "rgb(102, 85, 211)" } : item.select === 10 ? { background: "#888" } : { background: "rgb(250, 60, 9)" } : item.select === 'R' ? { background: "rgb(250, 60, 9)" } : item.select === 'G' ? { background: "rgb(0, 194, 130)" } : { background: "rgb(102, 85, 211)" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="parity__user__order__box__head__select">
                                        <div style={{ height: 30, width: 30 }}>
                                            <div className="parity__records__circle">
                                                <div className="parity__records__circle__no">{!item.result || item.result === 10 ? "" : item.result}</div>
                                                <div className="parity__records__circle__inner">
                                                    <div className="parity__records__circle__col" style={item.result === 1 || item.result === 3 || item.result === 7 || item.result === 9 || item.result === 5 ? { background: "rgb(0, 194, 130)" } : !item.result ? { background: "#888" } : { background: "rgb(250, 60, 9)" }}></div>
                                                    <div className="parity__records__circle__col" style={item.result === 1 || item.result === 3 || item.result === 7 || item.result === 9 ? { background: "rgb(0, 194, 130)" } : item.result === 2 || item.result === 4 || item.result === 6 || item.result === 8 ? { background: "rgb(250, 60, 9)" } : item.result === 0 || item.result === 5 ? { background: "rgb(102, 85, 211)" } : { background: "#888" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="parity__user__order__box__head__amount">₹{item.amount}</div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default FastParity