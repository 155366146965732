import React, { useState, useEffect } from 'react'
import './Recharge.css'
import { IoIosArrowRoundBack } from 'react-icons/io'
import axios from 'axios'
import Loading from '../../Components/Loading'
import RechargeScreen from './RechargeScreen'
import { ToastContainer, toast } from 'react-toast'

const RechargeRecords = () => {
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState(null)
    const [isData, setIsData] = useState(false)

    async function fetchBal() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/rechargeRecords`, { id: user })
            .then(response => {
                if (response.data.isData) {
                    setIsData(true);
                    setData(response.data.data)
                }
                setLoader(false)
            }).catch(error => {
                setLoader(false)
                toast.error(error.response.data?.error)
            })
    }

    useEffect(() => {
        fetchBal()
    }, [])

    return (
        <div>
            {loader ? <Loading /> : <></>}

            <div className='app__top__nav'>
                <div className="top__nav__cols" style={{ flexBasis: 50, width: '100%' }}>
                    <div className="top__nav__back__btn" onClick={() => window.location.replace('/recharge')}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="top__nav__cols" style={{ flexBasis: "calc(100% - 100px)", width: '100%' }}>
                    <center>
                        <div className="top__nav__title" style={{ fontSize: 16, fontWeight: 500 }}>Recharge Records</div>
                    </center>
                </div>
                <div className="top__nav__cols" style={{ flexBasis: 50, width: '100%' }}></div>
            </div>

            {isData ? (
                data.map((item, index) => {
                    return (
                        <div className="rechargeRecord__screen" key={index}>
                            <div className="rechargeRecord__box">
                                <div className="rechargeRecord__box__top">
                                    <div className="rechargeRecord__box__top__left">
                                        <div className="rechargeRecord__id">{item.orderId}</div>
                                    </div>
                                    <img src="https://www.vectorlogo.zone/logos/upi/upi-ar21.png" height="35px" alt="" />
                                </div>
                                <div className="rechargeRecord__box__top" style={{ padding: '10px 5%', paddingTop: 0}}>
                                    <div className="rechargeRecord__amount">{item.amount}<span style={{ fontSize: 15}}>points</span></div>
                                    <div className="rechargeRecord__time">{item.date}</div>
                                </div>
                                <div className="rechargeRecord__box__bottom"></div>
                            </div>
                        </div>
                    )
                })
            ):(
                <div className="noRecord">No Records!</div>
            )}

            <ToastContainer delay={3000} position={'top-center'} />
        </div>
    )
}

export default RechargeRecords