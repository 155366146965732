import './App.css';
import Register from './Pages/Auth/Register'
import Login from './Pages/Auth/Login'
import Recharge from './Pages/Recharge/Recharge';
import Profile from './Pages/Profile/Profile'
import Home from './Pages/Home/Home';
import FastParity from './Pages/Games/Parity/FastParity';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import CheckIn from './Pages/Checkin/CheckIn'
import Invite from './Pages/Invite/Invite';
import Withdrawal from './Pages/Withdrawal/Withdrawal';
import Task from './Pages/Task/Task';
import PaymentMethods from './Pages/Withdrawal/PaymentMethods';
import AddCard from './Pages/Withdrawal/AddCard';
import OrderRecords from './Pages/Profile/OrderRecords';
import FinancialRecords from './Pages/Profile/FinancialRecords';
import RechargeRecords from './Pages/Recharge/RechargeRecords';
import Team from './Pages/Invite/Team';
import Dice from './Pages/Games/Dice/Dice';
import Minesweeper from './Pages/Games/Minesweeper/Minesweeper'
import Ranking from './Pages/Invite/Ranking';
import AgentIncome from './Pages/Invite/AgentIncome';
import Privilege from './Pages/Invite/Privilege'
import Lifafa from './Pages/Invite/Lifafa'
import Commission from './Pages/Invite/Commission'
import DailyRec from './Pages/Invite/DailyRec'
import InviteLink from './Pages/Invite/InviteLink';

function App() {
  const user = localStorage.getItem('user')

  return (
    <div className="App">
      <div className="app__responsive">
         <BrowserRouter>
          {user ? (
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/my' element={<Profile />} />
              <Route path='/recharge' element={<Recharge />} />
              <Route path='/check-in' element={<CheckIn />} />
              <Route path='/invite' element={<Invite />} />
              <Route path='/withdraw' element={<Withdrawal />} />
              <Route path='/task' element={<Task />} />
              <Route path='/team' element={<Team />} />
              <Route path='/game/fastParity' element={<FastParity />} />
              <Route path='/game/dice' element={<Dice />} />
              <Route path='/paymentMethods' element={<PaymentMethods />} />
              <Route path='/addCard' element={<AddCard />} />
              <Route path='/orderRecords' element={<OrderRecords />} />
              <Route path='/financialRecords' element={<FinancialRecords />} />
              <Route path='/rechargeRecords' element={<RechargeRecords />} />
              <Route path='/game/minesweeper' element={<Minesweeper />} />
              <Route path='/ranking' element={<Ranking />} />
              <Route path='/agentPlan' element={<AgentIncome />} />
              <Route path='/lifafa' element={<Lifafa />} />
              <Route path='/privilege' element={<Privilege />} />
              <Route path='/commission' element={<Commission />} />
              <Route path='/daily-rec' element={<DailyRec />} />
              <Route path='/InviteLink' element={<InviteLink />} />
              <Route path='*' element={<Home />} />
            </Routes>
          ) : (
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='*' element={<Login />} />
              <Route path='/register' element={<Register />} />
            </Routes>
          )}
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
