import React, { useState, useEffect } from 'react'
import { IoIosArrowRoundBack } from 'react-icons/io'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toast'
import Loading from '../../Components/Loading'

const Commission = () => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    async function getData() {
        let user = localStorage.getItem("user");

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetchReff`, { id: user }).then(response3 => {
            setLoader(false)
            setData(response3.data.data)
        }).catch(error => {
            setLoader(false)
            toast.error(error.response.data?.error)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div style={{ minHeight: '100vh', background: '#fff'}}>
            {loader ? <Loading /> : <></>}
            <div className='app__top__nav' style={{ background: '#fff', borderBottom: '1.5px solid #bbb' }}>
                <div className="top__nav__cols" style={{ flexBasis: 50, width: '100%' }}>
                    <div className="top__nav__back__btn" onClick={() => window.history.go(-1)}>
                        <IoIosArrowRoundBack />
                    </div>
                </div>
                <div className="top__nav__cols" style={{ flexBasis: "calc(100% - 100px)", width: '100%' }}>
                    <center>
                        <div className="top__nav__title" style={{ fontSize: 16, fontWeight: 500 }}>Income Details</div>
                    </center>
                </div>
                <div className="top__nav__cols" style={{ flexBasis: 50, width: '100%' }}></div>
            </div>

            <div className="invitePageIncomeDetailsBottom">
                {data.length === 0 ? (
                    <div className="orderRecordSillyText">No Record Found!</div>
                ) : (
                    data.map((item, index) => {
                        return (
                            <div className="invitePageIncomeBox" key={index}>
                                <div className="invitePageIncomeBoxLeft">
                                    <div className="invitePageIncomeBoxIcon">
                                        <img src={item.image} width="100%" style={{ borderRadius: 100 }} alt="" />
                                    </div>
                                    <div className="invitePageIncomeBoxLeftRight">
                                        <div className="invitePageIncomeDetail1">{item.title}</div>
                                        <div className="invitePageIncomeDetailBottom">
                                            <div className="invitePageIncomeTime">{item.time} &nbsp;&nbsp;&nbsp; from {item.user}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="invitePageIncomeAmount">+₹{item.commission}</div>
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}

export default Commission